import styled from "styled-components";

interface ColoredThumbnailProps {
  color: string;
  size?: "Small" | "ExtraSmall";
}

export const ColoredThumbnail = styled.div.attrs<ColoredThumbnailProps>(props => ({
  className: `Polaris-Thumbnail Polaris-Thumbnail--size${props.size ?? "Small"} Polaris-Thumbnail--transparent`,
  "data-testId": "Polaris-Colored-Thumbnail",
}))<ColoredThumbnailProps>`
  background-color: ${props => props.color};
`;
