import { LoyaltyApi } from "@smartrr/shared/interfaces/loyalty/api";
import { createInteractiveTable } from "@vendor-app/app/_sharedComponents/InteractiveDataTable";
import { z } from "zod";
import { LoyaltyRewardsTableColumns } from "./columns";
import { LoyaltyRewardsTableSorts } from "./sorts";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export namespace RewardItem {
  export const schema = z
    .object({
      badge: z.custom<React.JSX.Element>(() => true).optional(),
      pointCostString: z.string().optional(),
    })
    .and(LoyaltyApi.RewardProduct.schema.or(LoyaltyApi.Incentive.schema));
  export type Type = z.infer<typeof schema>;
  export type RewardType = RewardItem.Type["rewardType"];
}

const initialData: RewardItem.Type[] = [];

type FiltersAny = any;

interface LoyaltyRewardsTablePersistType {
  selectedColumns: LoyaltyRewardsTableColumns.SelectedColumn[];
  selectedSort: LoyaltyRewardsTableSorts.SelectedSort;
}

const CURRENT_STORAGE_VERSION = 2;

export const LoyaltyRewardsTableAccess = createInteractiveTable<
  RewardItem.Type,
  FiltersAny, // Filters are being omitted
  LoyaltyRewardsTableColumns.SelectedColumn,
  LoyaltyRewardsTableSorts.SelectedSort,
  LoyaltyRewardsTablePersistType
>({
  initialData,
  initialFilters: [],
  initialSorts: [...LoyaltyRewardsTableSorts.types],
  async onUpdateTable() {
    const response = await typedFrontendVendorApi.getReq("/reward-program", { cache: "no-cache" });

    if (response.type === "success") {
      const variantsAndIncentives = [...response.body.variants, ...response.body.incentives];
      return {
        data: variantsAndIncentives,
        maxPages: 1,
      };
    }
    return {
      data: [],
      maxPages: 0,
    };
  },
  initialPagination: {
    pageNumber: 1,
    maxPages: 1,
  },
  initialSelectedFilters: [],
  initialSelectedSort: {
    sortType: "points",
    value: "ASC",
  },
  initialSelectedColumns: LoyaltyRewardsTableColumns.types.map(columnType => ({
    columnType,
    value: true,
  })),
  persistSettings: {
    name: "smartrr-loyalty-table-settings",
    version: CURRENT_STORAGE_VERSION,
    partialize: store => ({
      selectedColumns: store.selectedColumns,
      selectedSort: store.selectedSort,
    }),
    migrate(storedState, version) {
      if (version === CURRENT_STORAGE_VERSION) {
        return storedState as LoyaltyRewardsTablePersistType;
      }
    },
  },
});
