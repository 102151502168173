import { Text, VerticalStack } from "@shopify/polaris";

export const LoyaltyCurrencyHeader = () => {
  return (
    <VerticalStack gap="1">
      <Text as="span" variant="headingSm">
        Currency multipliers
      </Text>
      <Text as="span" variant="bodyMd" color="subdued">
        Set up point multipliers for redeeming items for each currency on your Shopify store that you&apos;ve
        enabled.
      </Text>
    </VerticalStack>
  );
};
