import React from "react";
import { Banner, Modal, Text } from "@shopify/polaris";
import { createSmartrrModal } from "@vendor-app/utils/createModalStore";

export const useTiersValidationModalStore = createSmartrrModal<{}>();

export const LoyaltyTiersValidationModal = () => {
  const { closeModal, isModalOpen, modalPayload } = useTiersValidationModalStore();

  if (!modalPayload) {
    return <React.Fragment />;
  }

  return (
    <Modal
      sectioned
      title="Warning"
      open={isModalOpen}
      onClose={closeModal}
      primaryAction={{
        content: "Go back",
        async onAction() {
          closeModal();
        },
      }}
    >
      <Banner status="warning">
        <Text as="span" variant="bodyMd">
          {"Please update loyalty tiers to have valid values before saving."}
        </Text>
      </Banner>
    </Modal>
  );
};
