import { createSmartrrModal } from "@vendor-app/utils/createModalStore";
import { Modal, Text, VerticalStack } from "@shopify/polaris";
import { LoyaltyStoreAccess } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store";
import React from "react";

export const useLoyaltyTiersRemoveModalStore = createSmartrrModal<{
  index: number;
}>();

export const LoyaltyTiersRemoveModal = () => {
  const { closeModal, isModalOpen, modalPayload } = useLoyaltyTiersRemoveModalStore();
  const { tiers } = LoyaltyStoreAccess.useActions();

  if (!modalPayload) {
    return <React.Fragment />;
  }

  return (
    <Modal
      sectioned
      title="Customers already in this tier"
      open={isModalOpen}
      onClose={closeModal}
      primaryAction={{
        destructive: true,
        content: "Delete tier",
        onAction() {
          tiers.remove(modalPayload?.index);
          closeModal();
        },
      }}
      secondaryActions={[{ content: "Cancel", destructive: false, onAction: closeModal }]}
    >
      <VerticalStack gap={"3"}>
        <Text as="span">
          Are you sure you want to delete this tier? There are customers that are already part of this tier.
        </Text>
        <Text as="span">Deleting this will move those customers down into a lower tier.</Text>
      </VerticalStack>
    </Modal>
  );
};
