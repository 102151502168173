import React, { useCallback, useMemo } from "react";
import { Button, TextField } from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import styled from "styled-components";
import { ColorSelectInput } from "../../../LoyaltyRewards/modals/components/ColorSelectInput";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { NumberedTextField } from "@vendor-app/app/_sharedComponents/NumberedTextField";
import { LoyaltyStoreAccess } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store";
import { LoyaltyApi } from "@smartrr/shared/interfaces/loyalty/api";
import { useLoyaltyTiersRemoveModalStore } from "../LoyaltyTiersRemoveModal";
import { getDuplicatesFromArray } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/libs/utils/getDuplicatesFromArray";

const TierInputsWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;

  .Polaris-Label__Text {
    display: none;
  }

  .Polaris-Labelled__LabelWrapper {
    position: static;
  }

  .smartrr-loyalty-events-delete {
    display: "flex";
    align-items: "center";
  }

  & > div:nth-of-type(1) {
    width: 41%;
  }
  & > div:nth-of-type(2) {
    width: 27%;
  }
  & > div:nth-of-type(3) {
    width: 40%;
  }

  .Polaris-Thumbnail {
    position: absolute;
    top: 10px;
    left: 12px;
    z-index: 100;
  }

  .Polaris-TextField--disabled .Polaris-TextField__Suffix {
    margin-right: 40px;
  }
`;

interface LoyaltyTiersListItemProps {
  tier: LoyaltyApi.Tier.Type;
  index: number;
}

export const LoyaltyTiersListItem = ({ tier, index }: LoyaltyTiersListItemProps) => {
  const { addToast } = useToast();
  const { rewardUnitNameSingular, rewardUnitNamePlural } = LoyaltyStoreAccess.useSavedValues();
  const { update, remove } = LoyaltyStoreAccess.useActions().tiers;
  const { tiers, tiersEnabled } = LoyaltyStoreAccess.useInput();
  const { openModal: openTiersRemoveModal } = useLoyaltyTiersRemoveModalStore();

  const equalValues = useMemo(() => getDuplicatesFromArray(tiers, ["products", "incentives"]), [tiers]);
  const tierEqualValues = useMemo(() => {
    const validation = equalValues.filter(value => value.indexes.includes(index));
    return validation;
  }, [tiers, equalValues, tier]);

  const hasError = useCallback(
    (fieldName: keyof LoyaltyApi.Tier.Type, value: string | number) => {
      return tierEqualValues.some(equalValue => equalValue[fieldName]) || value === "" || (value as number) <= 0;
    },
    [tiers]
  );

  const onDelete = useCallback(() => {
    if (tiers.length > 2) {
      if (tiersEnabled) {
        openTiersRemoveModal({ index });
      } else {
        remove(index);
      }
    } else {
      addToast("At least two tiers required");
    }
  }, [tiers, tiersEnabled]);

  return (
    <React.Fragment>
      <TierInputsWrapper key={index}>
        <TextField
          label="Tier name"
          value={tier.tierName}
          autoComplete="off"
          onChange={(v: string) => update(tier.id!, { ...tier, tierName: v }, index)}
          error={hasError("tierName", tier.tierName)}
        />
        <ColorSelectInput
          color={tier.tierColor}
          onChange={(v: string) => update(tier.id!, { ...tier, tierColor: v }, index)}
          error={hasError("tierColor", tier.tierColor)}
        />
        <NumberedTextField
          label="Requirement"
          labelHidden
          autoComplete="off"
          suffix={tier.minimumPoints === 1 ? rewardUnitNameSingular : rewardUnitNamePlural}
          value={Number(tier.minimumPoints)}
          type="number"
          min="0"
          align="left"
          disabled={index === 0}
          onChange={(v: string) => update(tier.id!, { ...tier, minimumPoints: Number(v) }, index)}
          error={index > 0 ? hasError("minimumPoints", tier.minimumPoints) : false}
        />
        <div className="smartrr-loyalty-events-delete" data-testid={"smartrr-test-loyalty-events-delete"}>
          <Button plain icon={DeleteMajor} onClick={onDelete} disabled={index <= 1} />
        </div>
      </TierInputsWrapper>
    </React.Fragment>
  );
};
