import { useMemo } from "react";
import { loyaltyTabs } from "../../libs/utils/constants";
import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";
import { useCurrentOrganization } from "@vendor-app/utils/useCurrentOrganization";

export const useFilteredLoyaltyTabs = () => {
  const flags = useSmartrrFlags();
  const organization = useCurrentOrganization();
  const currencyList = organization.shopifyStoreData?.enabled_presentment_currencies;
  return useMemo(() => {
    let filteredTabs = loyaltyTabs;
    if (!flags.tieredLoyalty) {
      filteredTabs = filteredTabs.filter(tab => tab.id !== "tiers");
    }
    const effectiveCurrencyList = currencyList ?? [];
    if (effectiveCurrencyList.length <= 1) {
      filteredTabs = filteredTabs.filter(tab => tab.id !== "currencies");
    }
    return filteredTabs;
  }, [flags.tieredLoyalty, currencyList, loyaltyTabs]);
};
