import { formatProductAndVariantNameForDisplay } from "@smartrr/shared/utils/displayUtils";
import { RewardItem } from ".";
import { LoyaltyRewardsTableColumns } from "./columns";
import { IndexTable, Text, Thumbnail, VerticalStack } from "@shopify/polaris";
import { isDefaultVariant } from "@smartrr/shared/utils/isDefaultVariant";
import { truncate } from "lodash";

import { ColoredThumbnail } from "../../components/ColoredThumbnail";
import React from "react";
import {
  isRewardItemProduct,
  getTiersFromIds,
  getTierText,
} from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/libs/utils/constants";
import { LoyaltyApi } from "@smartrr/shared/interfaces/loyalty/api";

export namespace LoyaltyRewardsTableCells {
  interface CellProps {
    row: RewardItem.Type;
    config: {
      currencySymbol: string;
      tiers: LoyaltyApi.Tier.Type[];
      tiersEnabled: boolean;
    };
  }
  export const Cells: Record<LoyaltyRewardsTableColumns.Column, (row: CellProps) => JSX.Element> = {
    image({ row }) {
      if (isRewardItemProduct(row)) {
        const displayName = formatProductAndVariantNameForDisplay({
          purchasableName: row.variant.productName,
          purchasableVariantName: row.variant.variantName,
        });
        return (
          <IndexTable.Cell>
            <Thumbnail
              source={row.variant.variantImages[0]}
              alt={`${displayName} Thumbnail Image`}
              size="small"
              transparent
            />
          </IndexTable.Cell>
        );
      }
      return (
        <IndexTable.Cell>
          <ColoredThumbnail color={row.color ?? ""} />
        </IndexTable.Cell>
      );
    },
    item({ row, config }) {
      if (isRewardItemProduct(row)) {
        return (
          <IndexTable.Cell>
            <VerticalStack gap={"0"}>
              <Text as="p" variant="bodyMd" fontWeight="medium">
                {truncate(row.variant.productName)}
              </Text>
              <Text as="p" variant="bodyMd" fontWeight="regular">
                {isDefaultVariant(row.variant.variantName) ? "" : row.variant.variantName}
              </Text>
            </VerticalStack>
          </IndexTable.Cell>
        );
      }
      return (
        <IndexTable.Cell>
          {row.priceRule?.type ? (
            <Text as="p" variant="bodyMd" fontWeight="regular">
              {row.priceRule.type === "fixed_amount" && config.currencySymbol
                ? `${config.currencySymbol}${-row.priceRule.value}`
                : `${-row.priceRule.value}%`}{" "}
              off
            </Text>
          ) : (
            <React.Fragment />
          )}
        </IndexTable.Cell>
      );
    },
    // Corresponds with Shopify Product / Variant status. Status is omitted for Incentives
    visibility({ row }) {
      if (row.badge) {
        return <IndexTable.Cell>{row.badge}</IndexTable.Cell>;
      }
      return <IndexTable.Cell />;
    },
    points({ row }) {
      return (
        <IndexTable.Cell>
          <Text as="p" variant="bodyMd" fontWeight="regular">
            {row.pointCostString}
          </Text>
        </IndexTable.Cell>
      );
    },
    restrictions({ row, config }) {
      const showTiers = config.tiersEnabled && !!row.tiers?.length;
      const rowTiers = row.rewardType === "INCENTIVE" ? getTiersFromIds(config.tiers, row.tiers) : row.tiers;
      const availabilityToDisplay = () => {
        if (showTiers && rowTiers) {
          return getTierText(rowTiers);
        }
        if (config.tiersEnabled && !row.tiers?.length) {
          return "All customers";
        }
        return row.subscriberOnly ? "Subscribers only" : "All customers";
      };
      return (
        <IndexTable.Cell>
          <Text as="p" variant="bodyMd" fontWeight="regular">
            {availabilityToDisplay()}
          </Text>
        </IndexTable.Cell>
      );
    },
  };
}
