import { NO_OP_CALLBACK } from "@smartrr/shared/constants";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const SwitchRoot = styled.div`
  cursor: pointer;
  display: inline-flex;
  gap: 5px;
  justify-content: space-between;
`;

const Label = styled.label.attrs((props: { disabled: boolean; switched: boolean }) => props)`
  font-size: 14px;
  line-height: 20px;
  color: ${props => (props.switched ? "#008060" : "#bdbdbd")};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  display: ${props => (props.disabled ? "none" : "init")};
`;

const Input = styled.input`
  display: none;
  height: 100%;
  width: 100%;
`;

const ToggleRoot = styled.div`
  display: block;
  padding: 4px 0px 6px 2px;
  position: relative;
  width: 36px;
`;

const ToggleButton = styled.div.attrs((props: { switched: boolean }) => props)`
  color: #000000dd;
  background-color: ${props => (props.switched ? "#008060" : "#f5f5f5")};
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  box-shadow:
    rgba(0, 0, 0, 0.12) 0px 1px 6px,
    rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: ${props => (props.switched ? "100%" : "0px")};
  width: 20px;
  height: 20px;
  line-height: 24px;

  margin-left: ${props => (props.switched ? "-20px" : "0")};
`;

const TogglePad = styled.div.attrs((props: { switched: boolean }) => props)`
  width: 100%;
  height: 14px;
  border-radius: 30px;
  background-color: ${props => (props.switched ? "#92E6B5" : "#bdbdbd")};
`;

interface Props {
  defaultToggled?: boolean;
  disabled?: boolean;

  onHandleChangeCB: (switched: boolean) => void;
  options?: {
    onTextOverride?: string;
    offTextOverride?: string;
  };
}

export const ToggleSwitch = ({
  defaultToggled = false,
  disabled,
  onHandleChangeCB,
  options,
}: Props): JSX.Element => {
  const [switched, setSwitched] = useState<boolean>(defaultToggled);

  const handleChange = () => {
    onHandleChangeCB(!switched);
    setSwitched(prev => !prev);
  };

  useEffect(() => {
    if (switched !== defaultToggled) {
      setSwitched(prev => !prev);
    }
  }, [defaultToggled]);

  return (
    <React.Fragment>
      <Input
        data-testid="toggle-switch-input"
        type="checkbox"
        role="checkbox"
        aria-disabled={disabled}
        checked={switched}
        disabled={disabled}
      />
      <SwitchRoot>
        <ToggleRoot
          className="toggle-root"
          onClick={disabled ? NO_OP_CALLBACK : handleChange}
          data-testid="input-toggle-switch"
        >
          <ToggleButton className="toggle-btn" switched={switched} />
          <TogglePad className="toggle-pad" switched={switched} />
        </ToggleRoot>
        <Label onClick={disabled ? NO_OP_CALLBACK : handleChange} switched={switched}>
          {switched ? options?.onTextOverride ?? "On" : options?.offTextOverride ?? "Off"}
        </Label>
      </SwitchRoot>
    </React.Fragment>
  );
};
