import React, { useMemo, useState } from "react";
import { Banner, Text } from "@shopify/polaris";
import { LoyaltyStoreAccess } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store";
import { useMyShopifyDomainSelector } from "@vendor-app/app/_state/reducers/shopify";
import { getFromLocalStorage, setInLocalStorage } from "@smartrr/shared/utils/localStorageAccess";

export const LoyaltyRewardsBanner = () => {
  const { tiersEnabled } = LoyaltyStoreAccess.useInput();
  const [showBanner, setShowBanner] = useState<boolean>(tiersEnabled);

  const myShopifyDomain = useMyShopifyDomainSelector();
  const localStorageKey = `${myShopifyDomain}:showRewardsBanner`;
  const localStorageEntry = useMemo(() => getFromLocalStorage(localStorageKey), [localStorageKey]);

  const hideBanner = !tiersEnabled || !showBanner || localStorageEntry === "false";

  if (hideBanner) {
    return <React.Fragment />;
  }

  return (
    <Banner
      status="success"
      title="You have tiers enabled"
      onDismiss={() => {
        setShowBanner(false);
        setInLocalStorage(localStorageKey, "false");
      }}
    >
      <Text as="span" variant="bodyMd">
        Set restrictions based on tier by selecting a reward from the table below and editing it&apos;s
        availability
      </Text>
    </Banner>
  );
};
