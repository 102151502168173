import { ActionList, Button, LegacyStack, Popover, Text } from "@shopify/polaris";
import { LoyaltyApi } from "@smartrr/shared/interfaces/loyalty/api";
import React, { useState } from "react";

import { LoyaltyStoreAccess } from "../../../store";
import { getEventNamesBasedOnType } from "../../../libs/utils/constants";

export const LoyaltyEventsHeader = (): JSX.Element => {
  const events = LoyaltyStoreAccess.useValue("events");
  const actions = LoyaltyStoreAccess.useActions();
  const { symbol: currencySymbol } = LoyaltyStoreAccess.useCurrency();

  const [eventPopoverActive, setEventPopoverActive] = useState(false);

  const eventsRemaining = LoyaltyApi.Enum.Event.arr.filter(eventType => {
    return events.find(event => event.type === eventType) === undefined;
  });

  return (
    <LegacyStack alignment="center" distribution="equalSpacing">
      <LegacyStack vertical spacing="extraTight">
        <Text as="h3" variant="headingSm">
          Loyalty structure
        </Text>
        <Text as="span" variant="bodyMd" color="subdued">
          Define how events earn customers points.
        </Text>
      </LegacyStack>
      <Popover
        active={eventPopoverActive}
        activator={
          <Button primary disabled={eventsRemaining.length === 0} onClick={() => setEventPopoverActive(true)}>
            Add Event
          </Button>
        }
        autofocusTarget="first-node"
        onClose={() => setEventPopoverActive(false)}
      >
        <ActionList
          actionRole="menuitem"
          items={eventsRemaining.map(event => {
            return {
              content: getEventNamesBasedOnType(event, currencySymbol),
              onAction: () => actions.events.addEvent(event),
            };
          })}
        ></ActionList>
      </Popover>
    </LegacyStack>
  );
};
