import { SelectOption } from "@shopify/polaris";
import styled from "styled-components";
import { LoyaltySelection } from "../../LoyaltyOTP";

export const LoyaltyRewardsModalWrapper = styled.div``;

export const InputSideBySideWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  & > div {
    flex: 1 0;
  }

  // Specific styles for forcing full-width for segmented button group
  .Polaris-ButtonGroup--segmented > div {
    flex: 1 0;
    button {
      width: 100%;
    }
  }
`;

export const InputSideBySideNoLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 15px;
  & > div {
    flex: 1 0;
  }
`;

export const rewardAvailabilitySelectOptions: SelectOption[] = [
  {
    label: "All customers",
    value: LoyaltySelection.all,
  },
  {
    label: "Subscribers only",
    value: LoyaltySelection.subscribers,
  },
];
