import { Button, Card, Divider, VerticalStack } from "@shopify/polaris";
import styled from "styled-components";
import { LoyaltyTiersListHeader } from "./LoyaltyTiersListHeader";
import { LoyaltyTiersListItem } from "./LoyaltyTiersListItem";
import React, { useMemo } from "react";
import { LoyaltyStoreAccess } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store";
import { MAX_TIERS } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/libs/utils/constants";
import { LoyaltyTiersErrorBanner } from "../LoyaltyTiersErrorBanner/LoyaltyTiersErrorBanner";

const TiersContainer = styled.div`
  display: flex;

  & > div:nth-of-type(1) {
    width: 36%;
  }
  & > div:nth-of-type(2) {
    width: 25%;
  }
`;

const AddTiersButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const LoyaltyTiersList = () => {
  const actions = LoyaltyStoreAccess.useActions();
  const { tiers } = LoyaltyStoreAccess.useInput();

  const mappedTiers = useMemo(() => {
    return [...tiers].map((tier, index) => <LoyaltyTiersListItem key={index} tier={tier} index={index} />);
  }, [tiers]);

  if (!mappedTiers.length) {
    return <React.Fragment />;
  }

  return (
    <Card>
      <VerticalStack gap="6">
        <LoyaltyTiersListHeader />
        <Divider />
        <VerticalStack>
          <LoyaltyTiersErrorBanner />
          <TiersContainer>
            <div>Tier name</div>
            <div>Color</div>
            <div>Requirement</div>
          </TiersContainer>
          {mappedTiers}
          <AddTiersButton>
            <Button onClick={() => actions.tiers.add()} primary size="slim" disabled={tiers.length === MAX_TIERS}>
              Add tier
            </Button>
          </AddTiersButton>
        </VerticalStack>
      </VerticalStack>
    </Card>
  );
};
