import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { LoyaltyStoreAccess } from "../../../store";
import retry from "async-retry";
import { noawait } from "@smartrr/shared/utils/noawait";
import { AnalyticsSegment } from "./components/AnalyticsSegment";
import { Card, Grid, VerticalStack } from "@shopify/polaris";
import { capitalize } from "lodash";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { AnalyticsTimeFrame } from "./components/AnalyticsTimeFrame";

const BlackCardWrapper = styled.span`
  // Black background
  .Polaris-Box {
    background-color: var(--p-color-bg-inverse);
    color: var(--p-color-text-inverse);
    overflow-y: hidden;
  }

  .Polaris-Grid-Cell {
    position: relative;

    @media screen and (max-width: 1040px) {
      grid-column-end: span 2; // ensuring grid cells stay inline at smaller vw
    }

    // Divider
    &:not(:first-child):before {
      content: " ";
      position: absolute;
      background-color: #2f2f2f;
      left: -17px;
      top: -20px;
      height: calc(100% + 40px); // compensating for parent container padding
      width: 2px;
    }
  }
`;

export const LoyaltyAnalytics = () => {
  const { loyaltyAnalytics: isLoyaltyAnalyticsEnabled } = useSmartrrFlags();
  const { addToast } = useToast();
  const { analytics } = LoyaltyStoreAccess.useActions();
  const { general: generalAnalytics } = LoyaltyStoreAccess.useAnalytics();
  const { showError, errorMessage } = LoyaltyStoreAccess.useErrors();
  const status = LoyaltyStoreAccess.useStatus();

  useEffect(() => {
    if (isLoyaltyAnalyticsEnabled && !generalAnalytics) {
      noawait(async () => await retry(analytics.fetch, { retries: 3 }));
    }
  }, []);

  useEffect(() => {
    if (showError && errorMessage) {
      addToast(errorMessage, true);
    }
  }, [showError]);

  if (!isLoyaltyAnalyticsEnabled || !generalAnalytics || status === "DISABLED") {
    return <React.Fragment />;
  }

  return <LoyaltyAnalyticsInner />;
};

const LoyaltyAnalyticsInner = () => {
  const { general: generalAnalytics } = LoyaltyStoreAccess.useAnalytics();
  const { pluralUnit } = LoyaltyStoreAccess.useRewardUnits();
  const pluralRewardUnitCapitalized = useMemo(() => capitalize(pluralUnit), [pluralUnit]);

  if (!generalAnalytics) {
    return <React.Fragment />;
  }

  const { pointsRedeemed, pointsAwarded, pointsInCirculation } = generalAnalytics;

  return (
    <VerticalStack gap={"5"} align="start">
      <AnalyticsTimeFrame />
      <BlackCardWrapper data-testid="loyalty-analytics-container">
        <Card>
          <Grid>
            <AnalyticsSegment
              sumInterval={pointsInCirculation}
              title={`${pluralRewardUnitCapitalized} in circulation`}
            />

            <AnalyticsSegment {...pointsRedeemed} title={`${pluralRewardUnitCapitalized} redeemed`} />

            <AnalyticsSegment {...pointsAwarded} title={`${pluralRewardUnitCapitalized} awarded`} />
          </Grid>
        </Card>
      </BlackCardWrapper>
    </VerticalStack>
  );
};
