import { useMemo } from "react";
import { LoyaltyRewardsTableColumns } from "../store/columns";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";

export const useLoyaltyRewardsTableHeadings = () =>
  useMemo(
    () =>
      Object.entries(LoyaltyRewardsTableColumns.availableColumns).map(([key, value]) => ({
        id: `table-column-${key}`,
        title: value.name,
        hidden: value.disabled,
      })) satisfies IndexTableHeading[] as unknown as NonEmptyArray<IndexTableHeading>,
    [LoyaltyRewardsTableColumns.availableColumns]
  );
