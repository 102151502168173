import { Card, ChoiceList, Text, VerticalStack } from "@shopify/polaris";

import { LoyaltyOTPConfirmationModal, useLoyaltyOTPConfirmationModal } from "./ConfirmationModal";
import { LoyaltyStoreAccess } from "../../../store";

export const LoyaltySelection = {
  subscribers: "subscribers",
  all: "all",
};

export const LoyaltyOTP = () => {
  const actions = LoyaltyStoreAccess.useActions();
  const rewards = LoyaltyStoreAccess.useRewards();
  const otpEnabled = LoyaltyStoreAccess.useValue("otpEnabled");
  const openModal = useLoyaltyOTPConfirmationModal(state => state.openModal);

  return (
    <Card>
      <VerticalStack>
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <Text as="h3" variant="headingSm" fontWeight="semibold">
            Loyalty availability
          </Text>
        </div>
        <ChoiceList
          title="Loyalty availability"
          titleHidden
          choices={[
            {
              label: "Subscribers only",
              value: LoyaltySelection.subscribers,
            },
            {
              label: "All customers",
              value: LoyaltySelection.all,
            },
          ]}
          selected={otpEnabled ? [LoyaltySelection.all] : [LoyaltySelection.subscribers]}
          onChange={s => {
            if (s.length === 1 && s[0] === LoyaltySelection.all) {
              actions.otp.update(true);
              openModal({});
            }
            if (s.length === 1 && s[0] === LoyaltySelection.subscribers) {
              actions.otp.update(false);
            }
          }}
          disabled={rewards?.otpEnabled ?? false}
        />
        <LoyaltyOTPConfirmationModal />
      </VerticalStack>
    </Card>
  );
};
