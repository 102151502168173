import { Popover, TextField } from "@shopify/polaris";
import { NO_OP_CALLBACK } from "@smartrr/shared/constants";
import { BaseChromePicker } from "@vendor-app/app/AdminRoute/AdminThemingRoute/components/ColorPicker";
import React, { useState } from "react";
import styled from "styled-components";
import { ColoredThumbnail } from "../../components/ColoredThumbnail";

const StyledColorInputWrapper = styled.div`
  position: relative;

  .Polaris-TextField__Input {
    padding-left: 42px;
  }
  .Polaris-Thumbnail {
    position: absolute;
    top: 30px;
    left: 12px;
    z-index: 100;
  }
`;

export const ColorSelectInput = ({
  onChange,
  color = "",
  error,
}: {
  color: string;
  onChange: (v: string) => void;
  error?: boolean;
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  return (
    <StyledColorInputWrapper>
      <Popover
        active={isPopoverOpen}
        activator={
          <span onClick={() => setIsPopoverOpen(prev => !prev)}>
            <ColoredThumbnail color={color} size="ExtraSmall" />
            <TextField
              value={color.toUpperCase()}
              onChange={NO_OP_CALLBACK}
              label="Color"
              autoComplete="off"
              error={error}
            />
          </span>
        }
        onClose={() => setIsPopoverOpen(false)}
      >
        <BaseChromePicker defaultColor={color} handleClose={NO_OP_CALLBACK} onChange={onChange} />
      </Popover>
    </StyledColorInputWrapper>
  );
};
