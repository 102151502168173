import { ZodSchema } from "zod";

export const displayError = <Type>(
  schema: ZodSchema,
  value: Type,
  showError: boolean,
  overrideErrorText?: string
) => {
  if (!showError) {
    return undefined;
  }
  const result = schema.safeParse(value);
  if (result.success) {
    return undefined;
  }
  if (result.error.issues.length === 0) {
    return undefined;
  }
  return overrideErrorText ? overrideErrorText : result.error.issues[0].message;
};
