import { Text } from "@shopify/polaris";
import styled from "styled-components";

const StyledEmptyStateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 200px;
  width: 100%;
`;

export const LoyaltyRewardsTableEmptyState = () => {
  return (
    <StyledEmptyStateWrapper>
      <Text as="span" color="subdued">
        No reward items yet. <br /> Try adding one above 👆
      </Text>
    </StyledEmptyStateWrapper>
  );
};
