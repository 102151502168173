import React, { useMemo } from "react";
import { Banner, Text, VerticalStack } from "@shopify/polaris";
import { DiamondAlertMajor } from "@shopify/polaris-icons";
import { LoyaltyStoreAccess } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store";
import { getDuplicatesFromArray } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/libs/utils/getDuplicatesFromArray";
import styled from "styled-components";
import { checkForEmptyValues } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/libs/utils/checkForEmptyValues";

const TiersErrorBannerWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const LoyaltyTiersErrorBanner = () => {
  const { tiers } = LoyaltyStoreAccess.useInput();
  const equalValues = useMemo(() => getDuplicatesFromArray(tiers, ["products", "incentives"]), [tiers]);
  // Excluding first index from checkForEmptyValues as minimumPoints should always be 0. Adding check for other properties here
  const firstIndexEmptyValues = useMemo(() => {
    return tiers[0].tierName === "" || tiers[0].tierColor === "";
  }, [tiers]);
  const emptyValues = useMemo(() => {
    return checkForEmptyValues(tiers, ["products", "incentives"], [0]) || firstIndexEmptyValues;
  }, [tiers]);

  const renderEqualValuesError = useMemo(() => {
    return equalValues.map((error, index) => {
      const keys = Object.keys(error);
      for (const key of keys) {
        switch (key) {
          case "tierName": {
            return (
              <Text key={index} as="span" variant="bodyMd">
                Tier names cannot be the same
              </Text>
            );
          }
          case "tierColor": {
            return (
              <Text key={index} as="span" variant="bodyMd">
                Tier colors cannot be the same
              </Text>
            );
          }
          case "minimumPoints": {
            return (
              <Text key={index} as="span" variant="bodyMd">
                Tier minimum amounts cannot be the same
              </Text>
            );
          }
          default: {
            <React.Fragment />;
          }
        }
      }
    });
  }, [tiers]);

  const renderEmptyValuesError = useMemo(() => {
    if (!emptyValues) {
      return <React.Fragment />;
    }

    return (
      <Text as="span" variant="bodyMd">
        Tier values cannot be empty or a negative value
      </Text>
    );
  }, [tiers]);

  if (equalValues.length === 0 && !emptyValues) {
    return <React.Fragment />;
  }

  return (
    <TiersErrorBannerWrapper>
      <Banner status="critical" icon={DiamondAlertMajor}>
        <VerticalStack gap={"1"}>
          {renderEmptyValuesError}
          {renderEqualValuesError}
        </VerticalStack>
      </Banner>
    </TiersErrorBannerWrapper>
  );
};
