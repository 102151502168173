import { Grid, HorizontalStack, Icon, Text, VerticalStack } from "@shopify/polaris";
import { ChevronDownMinor, ChevronUpMinor } from "@shopify/polaris-icons";
import { numberWithCommas } from "@smartrr/shared/utils/formatMoney";
import { kebabCase } from "lodash";
import React, { useMemo } from "react";

interface AnalyticsBannerSegment {
  title: string;
  sumInterval: number;
  sumIntervalBefore?: number;
  percentageDifference?: number;
}

export const AnalyticsSegment = ({
  title,
  sumInterval = 0,
  percentageDifference = 0,
}: AnalyticsBannerSegment) => {
  const isPositiveChange = useMemo(
    () => percentageDifference && percentageDifference > 0,
    [percentageDifference]
  );

  const hasChange = useMemo(() => percentageDifference && percentageDifference !== 0, [percentageDifference]);

  return (
    <Grid.Cell columnSpan={{ xs: 2, sm: 4 }}>
      <span data-testid={`loyalty-analytics-segment-${kebabCase(title.toLowerCase())}`}>
        <VerticalStack gap={"2"}>
          <Text as="h3" variant="bodyMd" fontWeight="regular">
            {title}
          </Text>
          <HorizontalStack gap={"2"} blockAlign="center">
            <Text as="p" variant="headingLg" fontWeight="regular">
              {numberWithCommas(sumInterval)}
            </Text>
            {!!percentageDifference && (
              <HorizontalStack blockAlign="center">
                {!!hasChange && (
                  <Icon
                    source={isPositiveChange ? ChevronUpMinor : ChevronDownMinor}
                    color={isPositiveChange ? "success" : "subdued"}
                  />
                )}
                <Text
                  as="p"
                  variant="bodySm"
                  fontWeight="regular"
                  color={isPositiveChange ? "success" : "subdued"}
                >
                  {Math.abs(percentageDifference)}%
                </Text>
              </HorizontalStack>
            )}
          </HorizontalStack>
        </VerticalStack>
      </span>
    </Grid.Cell>
  );
};
