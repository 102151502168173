import { createSmartrrModal } from "@vendor-app/utils/createModalStore";
import { useEffect, useState } from "react";
import { LoyaltyForm } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store/form";
import { EditingRewardProductModal } from "./EditingRewardProductModal";
import { SelectingRewardProductModal } from "./SelectingRewardProductModal";

interface LoyaltyProductModalProps {
  product: LoyaltyForm.RewardProduct.Type | undefined;
}

export const useLoyaltyProductModalStore = createSmartrrModal<LoyaltyProductModalProps>();

export const LoyaltyProductModal = () => {
  const { modalPayload: payload, isModalOpen: isOpen } = useLoyaltyProductModalStore(state => state);
  const [selectedIdToEdit, setSelectedIdToEdit] = useState<string | undefined>();

  useEffect(() => {
    // Resetting state after close
    if (!isOpen) {
      setSelectedIdToEdit(undefined);
    }
    // Modal will have already mounted, once opened we'll set the payload product if provided
    if (payload?.product?.shopifyId && !selectedIdToEdit) {
      setSelectedIdToEdit(payload?.product?.shopifyId);
    }
  }, [isOpen]);

  // If is editing
  if (!!selectedIdToEdit) {
    return (
      <EditingRewardProductModal
        selectedIdToEdit={selectedIdToEdit}
        clearSelectedIdToEdit={() => setSelectedIdToEdit(undefined)}
      />
    );
  }

  return <SelectingRewardProductModal setSelectedIdToEdit={product => setSelectedIdToEdit(product)} />;
};
