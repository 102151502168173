import { useCallback } from "react";
import { LoyaltyStoreAccess } from "../../store";
import { LoyaltyApi } from "@smartrr/shared/interfaces/loyalty/api";
import { cloneDeep } from "lodash";

const changeVariants = (
  variants: LoyaltyApi.RewardProduct.Type[],
  updateProduct: (index: number, variant: LoyaltyApi.RewardProduct.Type) => void
) => {
  for (const [index, variant] of variants.entries()) {
    // We only want to set reward items to hidden if
    // subscriberOnly is true
    if (variant.subscriberOnly) {
      variant.subscriberOnly = false;
      variant.hidden = true;
      updateProduct(index, variant);
    }
  }
};

const changeIncentives = (
  incentives: LoyaltyApi.Incentive.Type[],
  updateIncentive: (index: number, incentive: LoyaltyApi.Incentive.Type) => void
) => {
  for (const [index, incentive] of incentives.entries()) {
    if (incentive.subscriberOnly) {
      incentive.subscriberOnly = false;
      incentive.hidden = true;
      updateIncentive(index, incentive);
    }
  }
};

export const useSwitchRewardItemsToTiers = () => {
  const incentives = cloneDeep(LoyaltyStoreAccess.useInput().incentives);
  const variants = cloneDeep(LoyaltyStoreAccess.useInput().variants);
  const { update: updateIncentive } = LoyaltyStoreAccess.useActions().marketplace.incentive;
  const { update: updateProduct } = LoyaltyStoreAccess.useActions().marketplace.product;

  return useCallback(() => {
    if (variants) {
      changeVariants(variants, updateProduct);
    }

    if (incentives) {
      changeIncentives(incentives, updateIncentive);
    }
  }, [incentives, variants]);
};
