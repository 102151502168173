import { Card, VerticalStack } from "@shopify/polaris";
import { LoyaltyRewardsTabs } from "./LoyaltyRewardsTabs";
import { LoyaltyRewardsTable } from "./LoyaltyRewardsTable";
import { LoyaltyProductModal } from "./modals/LoyaltyProductModal";
import { LoyaltyIncentiveModal } from "./modals/LoyaltyIncentiveModal";
import { BulkDeleteRewardModal } from "./modals/BulkDeleteRewardModal";
import { RewardSettingsCard } from "../../RewardSettingsCard";
import { LoyaltyRewardsBanner } from "./LoyaltyRewardsBanner";

export const LoyaltyRewards = () => (
  <VerticalStack gap={"5"}>
    <LoyaltyRewardsBanner />
    <Card padding={"0"}>
      <LoyaltyRewardsTabs />
      <LoyaltyRewardsTable />
    </Card>
    <RewardSettingsCard />
    {/* Modal Views - Not rendered until opened */}
    <LoyaltyProductModal />
    <LoyaltyIncentiveModal />
    <BulkDeleteRewardModal />
  </VerticalStack>
);
