import { Checkbox, Text, Banner } from "@shopify/polaris";
import React, { useState, useCallback } from "react";
import styled from "styled-components";

const VisibilityCheckboxWrapper = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 16px;

  & .Polaris-Icon {
    margin: 0;
  }

  .Polaris-Choice__Label {
    white-space: nowrap;
  }
`;

export interface VisibilityCheckboxProps {
  disabled?: boolean;
  value: boolean;
  onChange: (v: boolean) => void;
  isDraftOrArchived?: boolean;
  isVariantOutOfStock?: boolean;
}

export const VisibilityCheckbox = ({
  disabled,
  value,
  onChange,
  isDraftOrArchived,
  isVariantOutOfStock,
}: VisibilityCheckboxProps) => {
  const [checked, setChecked] = useState(value);
  const handleChange = useCallback((newChecked: boolean) => {
    setChecked(newChecked);
    onChange(!newChecked);
  }, []);
  return (
    <VisibilityCheckboxWrapper data-testid="visibility-checkbox">
      <Checkbox
        label={"Visible in reward marketplace"}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
      {isDraftOrArchived ? (
        <Banner status="warning">
          <Text as="p">
            This item&apos;s status is{" "}
            <Text as="span" fontWeight="bold">
              Draft/Archive
            </Text>{" "}
            and will not be visible to customers. It will be visible once it&apos;s{" "}
            <Text as="span" fontWeight="bold">
              Active
            </Text>
            .
          </Text>
        </Banner>
      ) : (
        <React.Fragment />
      )}
      {isVariantOutOfStock ? (
        <Banner status="warning">
          <Text as="p">
            This item is out of stock and has been hidden from customers temporarily. It will be visible once back
            in stock.
          </Text>
        </Banner>
      ) : (
        <React.Fragment />
      )}
    </VisibilityCheckboxWrapper>
  );
};
