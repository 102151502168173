import { Banner, Button, LegacyCard, LegacyStack, Spinner, Text } from "@shopify/polaris";
import { CurrencyCode } from "@smartrr/shared/shopifyGraphQL/api";
import { noawait } from "@smartrr/shared/utils/noawait";
import React, { useEffect } from "react";
import styled from "styled-components";

import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";

import { LoyaltyStoreAccess } from "../../store";
import { ShowLoyalty } from "../ShowLoyalty";

const NoRewardsTitle = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
`;

const NoRewardsDescription = styled.p`
  color: #6d7175;
  text-align: center;
`;

const NoRewardProgram = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 100px 20px;
`;

export const FetchLoyalty = (): JSX.Element => {
  const actions = LoyaltyStoreAccess.useActions();
  const rewards = LoyaltyStoreAccess.useRewards();
  const isLoading = LoyaltyStoreAccess.useLoading();
  const hasError = LoyaltyStoreAccess.useShowError();
  const organization = useActiveOrganizationSelector();

  useEffect(() => {
    noawait(async () => {
      actions.initialize();
    });
  }, [!rewards && !isLoading && !hasError]);

  useEffect(() => {
    actions.currency.update(organization?.shopifyStoreData?.currency ?? CurrencyCode.Usd);
  }, [organization?.shopifyStoreData]);

  if (isLoading) {
    return (
      <LegacyStack alignment="center" distribution="center">
        <Spinner />
      </LegacyStack>
    );
  }

  if (!rewards && !isLoading) {
    return (
      <LegacyCard>
        <NoRewardProgram>
          <NoRewardsTitle>You haven&apos;t enabled a loyalty program yet</NoRewardsTitle>
          <NoRewardsDescription>
            Incentivize your customers to earn and redeem rewards through different shop touchpoints.
          </NoRewardsDescription>
          <Button primary onClick={() => noawait(() => actions.create())}>
            Get started
          </Button>
          <Banner title="Note" status="info">
            <Text as="p" variant="bodyMd">
              By clicking &quot;Get Started,&quot; your loyalty program will activate in preview mode. In this
              mode, customers will not earn points or receive email notifications about points until you fully
              enable the loyalty program.
            </Text>
          </Banner>
        </NoRewardProgram>
      </LegacyCard>
    );
  }

  return <ShowLoyalty />;
};
