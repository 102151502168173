import {
  ActionList,
  ActionListItemDescriptor,
  Button,
  IndexFilters,
  Popover,
  TabProps,
  useSetIndexFiltersMode,
} from "@shopify/polaris";
import { NO_OP_CALLBACK } from "@smartrr/shared/constants";
import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { useLoyaltyProductModalStore } from "../modals/LoyaltyProductModal";
import { useLoyaltyIncentiveModalStore } from "../modals/LoyaltyIncentiveModal";
import { LoyaltyStoreAccess } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store";
import { RewardItem } from "../LoyaltyRewardsTable/store";

const StyledIndexFiltersWrapper = styled.div`
  position: relative;
  // Forcibly hiding search / filtering as IndexFilters do not allow this through their props
  .Polaris-IndexFilters__ActionWrap {
    pointer-events: none;
    display: none;
    visibility: hidden;
  }

  .IndexFilters_CustomActions {
    position: absolute;
    top: 11px;
    right: 12px;
  }
`;

export const LoyaltyRewardsTabs = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0); // Tab index
  const { mode, setMode } = useSetIndexFiltersMode();
  const openLoyaltyProductModal = useLoyaltyProductModalStore(state => state.openModal);
  const openLoyaltyIncentiveModal = useLoyaltyIncentiveModalStore(state => state.openModal);
  const [isAddRewardPopoverActive, setAddRewardPopoverActive] = useState<boolean>(false);

  const actions = LoyaltyStoreAccess.useActions();

  const toggleRewardPopover = useCallback(() => setAddRewardPopoverActive(prev => !prev), []);

  const popoverActions: ActionListItemDescriptor[] = [
    {
      content: "Add product",
      onAction() {
        openLoyaltyProductModal({ product: undefined });
        setAddRewardPopoverActive(false);
      },
    },
    {
      content: "Add incentive",
      onAction() {
        openLoyaltyIncentiveModal({ incentive: undefined });
        setAddRewardPopoverActive(false);
      },
    },
  ];

  const setFilter = useCallback(
    (filter: RewardItem.RewardType | null) =>
      filter ? actions.marketplace.filter.set(filter) : actions.marketplace.filter.reset(),
    [actions]
  );

  const LoyaltyMarketplaceTabs: TabProps[] = useMemo(
    () => [
      {
        index: 0,
        id: `marketplace-tabs-0`,
        content: "All",
        onAction: () => setFilter(null),
      },
      {
        index: 1,
        id: `marketplace-tabs-1`,
        content: "Products",
        onAction: () => setFilter("PRODUCT"),
      },
      {
        index: 2,
        id: `marketplace-tabs-2`,
        content: "Incentives",
        onAction: () => setFilter("INCENTIVE"),
      },
    ],
    []
  );

  return (
    <StyledIndexFiltersWrapper>
      <IndexFilters
        appliedFilters={[]}
        filters={[]}
        tabs={LoyaltyMarketplaceTabs}
        selected={selectedTab}
        onSelect={i => setSelectedTab(i)}
        mode={mode}
        setMode={setMode}
        onClearAll={NO_OP_CALLBACK}
        onQueryChange={NO_OP_CALLBACK}
        onQueryClear={NO_OP_CALLBACK}
        cancelAction={{
          onAction: NO_OP_CALLBACK,
          disabled: false,
          loading: false,
        }}
        canCreateNewView={false}
        disableQueryField
      />
      <span className="IndexFilters_CustomActions">
        <Popover
          active={isAddRewardPopoverActive}
          onClose={toggleRewardPopover}
          activator={
            <Button onClick={toggleRewardPopover} primary size="slim">
              Add reward item
            </Button>
          }
        >
          <ActionList actionRole="menuitem" items={popoverActions} />
        </Popover>
      </span>
    </StyledIndexFiltersWrapper>
  );
};
