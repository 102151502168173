import { HorizontalStack, Icon, Text } from "@shopify/polaris";
import { CalendarMinor } from "@shopify/polaris-icons";
import styled from "styled-components";

const AnalyticsTimeFrameWrapper = styled.span`
  & .Polaris-Icon {
    margin: 0;
  }
`;

export const AnalyticsTimeFrame = () => {
  return (
    <AnalyticsTimeFrameWrapper data-testid="loyalty-analytics-time-frame">
      <HorizontalStack gap={"2"} align="start" blockAlign="center">
        <Icon source={CalendarMinor} color="subdued" />
        <Text as="p" variant="bodyMd" color="subdued">
          Last 7 days
        </Text>
      </HorizontalStack>
    </AnalyticsTimeFrameWrapper>
  );
};
