import { Card, Text, TextField, VerticalStack } from "@shopify/polaris";
import { LoyaltyValidation } from "@smartrr/shared/interfaces/loyalty/validation";
import { pluralizeForPrefix } from "@smartrr/shared/utils/pluralize";
import React from "react";
import styled from "styled-components";

import { LoyaltyStoreAccess } from "../../../store";
import { displayError } from "../../../libs/utils/displayError";
import { ToggleSwitch } from "../../ToggleSwitch";

const BonusEarnContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 14px;
  margin-top: 12px;
`;

export const LoyaltyBonus = () => {
  const bonus = LoyaltyStoreAccess.useValue("bonus");
  const actions = LoyaltyStoreAccess.useActions();
  const showError = LoyaltyStoreAccess.useShowError();

  return (
    <Card>
      <div
        style={{
          display: "flex",
        }}
      >
        <VerticalStack gap="1">
          <Text as="h3" variant="headingSm">
            Bonus earn
          </Text>
          <Text as="span" variant="bodyMd" color="subdued">
            Automatically give your customer a certain number of bonus points after certain number of subscription
            orders. For example, if you put in &quot;5&quot;, the customer will earn bonus points on the 5th,
            10th, 15th, 20th etc. orders.
          </Text>
        </VerticalStack>
        <ToggleSwitch defaultToggled={!!bonus} onHandleChangeCB={() => actions.bonus.toggle()} />
      </div>
      {bonus ? (
        <BonusEarnContainer>
          <TextField
            label="Value"
            suffix={pluralizeForPrefix(bonus.givePoints, "point")}
            value={String(bonus.givePoints)}
            type="number"
            min={0}
            autoComplete="off"
            onChange={s => {
              actions.bonus.update({
                ...bonus,
                givePoints: Number(s),
              });
            }}
            error={displayError(
              LoyaltyValidation.Bonus.schema.shape.givePoints,
              bonus.givePoints,
              showError,
              "Please add a point value to bonus earn"
            )}
          />
          <TextField
            label="Number of orders"
            suffix={pluralizeForPrefix(bonus.every, "order")}
            value={String(bonus.every)}
            type="number"
            min={0}
            autoComplete="off"
            onChange={s => {
              actions.bonus.update({
                ...bonus,
                every: Number(s),
              });
            }}
            error={displayError(
              LoyaltyValidation.Bonus.schema.shape.every,
              bonus.every,
              showError,
              "Please add a number of orders to bonus earn"
            )}
          />
        </BonusEarnContainer>
      ) : null}
    </Card>
  );
};
