import React from "react";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { Text, Tooltip, VerticalStack } from "@shopify/polaris";
import { VariantOrProductTitle } from "@vendor-app/app/AdminRoute/AdminSellingPlanGroupsRoute/constants";
import { StatusBadge } from "@vendor-app/app/_sharedComponents/StatusBadge";
import { truncate } from "lodash";

interface RewardOptionListLabelProps {
  allVariantsSelected: boolean;
  purchasable: IPurchasable;
  variantNames: string | undefined;
}

export const RewardOptionListLabel = ({
  allVariantsSelected,
  purchasable,
  variantNames,
}: RewardOptionListLabelProps) => {
  const OptionListLabelInner = () => (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <VerticalStack>
        <Text as="span" variant="bodyMd" fontWeight="medium">
          {truncate(purchasable.purchasableName)}
        </Text>
        {variantNames ? <VariantOrProductTitle>{variantNames}</VariantOrProductTitle> : <React.Fragment />}
      </VerticalStack>
      <StatusBadge obj={purchasable} />
    </div>
  );

  if (allVariantsSelected) {
    return (
      <Tooltip content="This item and all it's variants have already been added as rewards.">
        <OptionListLabelInner />
      </Tooltip>
    );
  }

  return <OptionListLabelInner />;
};
