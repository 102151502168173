import { Text, VerticalStack } from "@shopify/polaris";

export const LoyaltyTiersListHeader = () => {
  return (
    <VerticalStack gap="1">
      <Text as="h3" variant="headingSm">
        Tier list
      </Text>
      <Text as="span" variant="bodyMd" color="subdued">
        {
          "Offer up to 5 different levels of loyalty benefits based on a customer's point earn. Customers have a full calendar year from January 1st to December 31st to earn points and qualify for tiers."
        }
      </Text>
    </VerticalStack>
  );
};
