import { Text, TextField, VerticalStack } from "@shopify/polaris";
import { LoyaltyValidation } from "@smartrr/shared/interfaces/loyalty/validation";
import { LoyaltyStoreAccess } from "../../../store";
import { displayError } from "../../../libs/utils/displayError";
import styled from "styled-components";

const LoyaltyUnitsContainer = styled.div`
  display: flex;
  gap: 14px;
  & > div {
    flex: 1 0;
  }
`;

export const LoyaltyUnit = () => {
  const singular = LoyaltyStoreAccess.useValue("rewardUnitNameSingular");
  const plural = LoyaltyStoreAccess.useValue("rewardUnitNamePlural");
  const actions = LoyaltyStoreAccess.useActions();
  const showError = LoyaltyStoreAccess.useShowError();

  return (
    <VerticalStack gap={"3"}>
      <VerticalStack gap={"1"}>
        <Text as="h3" variant="headingSm" fontWeight="semibold">
          Loyalty unit
        </Text>
        <Text as="span" variant="bodyMd" color="subdued">
          While points are the default Smartrr reward unit, you have the option to add a custom unit name to make
          your rewards program unique to your brand.
        </Text>
      </VerticalStack>
      <LoyaltyUnitsContainer>
        <TextField
          label="Singular"
          value={singular}
          autoComplete="off"
          onChange={s => actions.units.updateSingular(s)}
          error={displayError(LoyaltyValidation.Program.schema.shape.rewardUnitNameSingular, singular, showError)}
        ></TextField>
        <TextField
          label="Plural"
          value={plural}
          autoComplete="off"
          onChange={s => actions.units.updatePlural(s)}
          error={displayError(LoyaltyValidation.Program.schema.shape.rewardUnitNamePlural, plural, showError)}
        ></TextField>
      </LoyaltyUnitsContainer>
    </VerticalStack>
  );
};
