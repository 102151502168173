import { Badge, IndexTable } from "@shopify/polaris";
import { LoyaltyStoreAccess } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store";
import React, { useCallback, useMemo } from "react";
import { LoyaltyRewardsTableAccess, RewardItem } from "./store";
import { LoyaltyRewardsTableCells } from "./store/cells";
import { usePurchasableVariants } from "@vendor-app/app/_state/reducers/purchasables";
import {
  getRewardIncentiveBadgeTitle,
  getRewardItemBadgeStatusAndTitle,
  isRewardItemProduct,
} from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/libs/utils/constants";
import { useLoyaltyProductModalStore } from "../modals/LoyaltyProductModal";
import { useLoyaltyIncentiveModalStore } from "../modals/LoyaltyIncentiveModal";

export const TableRow = ({ row, position }: { position: number; row: RewardItem.Type }) => {
  const columns = LoyaltyRewardsTableAccess.useEnabledColumns();
  const cells = useMemo(() => columns.map(c => LoyaltyRewardsTableCells.Cells[c]), [columns]);
  const openLoyaltyProductModal = useLoyaltyProductModalStore(state => state.openModal);
  const openLoyaltyIncentiveModal = useLoyaltyIncentiveModalStore(state => state.openModal);
  const isSelected = LoyaltyRewardsTableAccess.useIsSelected(row.id);
  const { symbol } = LoyaltyStoreAccess.useCurrency();
  const { tiers, tiersEnabled } = LoyaltyStoreAccess.useInput();

  const onModalOpen = useCallback(() => {
    if (isRewardItemProduct(row)) {
      openLoyaltyProductModal({ product: row });
    } else {
      openLoyaltyIncentiveModal({ incentive: row });
    }
  }, [row]);

  return (
    <IndexTable.Row id={row.id} onClick={onModalOpen} position={position} selected={isSelected}>
      {cells.map((Cell, key) => {
        return (
          <Cell
            key={key}
            row={row}
            config={{
              currencySymbol: symbol,
              tiers,
              tiersEnabled,
            }}
          />
        );
      })}
    </IndexTable.Row>
  );
};

export const LoyaltyRewardItemRows = () => {
  const rewardItems = LoyaltyStoreAccess.useRewardItems();
  const allVariants = usePurchasableVariants();
  const isLoyaltyAvailableToAll = LoyaltyStoreAccess.useValue("otpEnabled");
  const { singularUnit, pluralUnit } = LoyaltyStoreAccess.useRewardUnits();

  return rewardItems.map((row, index) => {
    let updatedRow: RewardItem.Type = row;
    if (isRewardItemProduct(row)) {
      const variant = allVariants.find(vnt => vnt.shopifyId === row.variant.shopifyId)!;
      const { status, title } = getRewardItemBadgeStatusAndTitle(variant, row.hidden);

      updatedRow = {
        ...row,
        badge: <Badge status={status}>{title}</Badge>,
      };
    } else {
      const title = getRewardIncentiveBadgeTitle(row.hidden);
      updatedRow = {
        ...row,
        badge: <Badge status={row.hidden ? "new" : "success"}>{title}</Badge>,
      };
    }

    // Overrides that apply to both reward variants and incentives
    updatedRow = {
      ...updatedRow,
      pointCostString: `${row.costInPoints} ${row.costInPoints === 1 ? singularUnit : pluralUnit}`,
      subscriberOnly: isLoyaltyAvailableToAll ? row.subscriberOnly : true,
    };

    return <TableRow key={row.id} position={index} row={updatedRow} />;
  });
};
