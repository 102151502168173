import { Banner, Modal, Text } from "@shopify/polaris";
import { noawait } from "@smartrr/shared/utils/noawait";
import { createSmartrrModal } from "@vendor-app/utils/createModalStore";
import React from "react";
import { LoyaltyStoreAccess } from "../../../store";

export const useLoyaltyConfirmationModalStore = createSmartrrModal<{
  values: {
    pointsInRewards: number;
    priceRuleValue: string;
  };
  actions: {
    save(): Promise<void>;
  };
}>();

export const LoyaltyConfirmationModal = () => {
  const { closeModal, isModalOpen, modalPayload } = useLoyaltyConfirmationModalStore();
  const rewardUnitNamePlural = LoyaltyStoreAccess.useValue("rewardUnitNamePlural");

  if (!modalPayload) {
    return <React.Fragment />;
  }

  const hasPointsInRewardsError = modalPayload.values.pointsInRewards === 0;
  const hasPriceRuleError = modalPayload.values.priceRuleValue === "0";

  const getErrorCopy = () => {
    const referrersCopy = `referrers with 0 ${rewardUnitNamePlural}`;
    const refereesCopy = `referees with 0 ${rewardUnitNamePlural}`;
    if (hasPointsInRewardsError && hasPriceRuleError) {
      return `${referrersCopy} and ${refereesCopy}`;
    }
    return hasPointsInRewardsError ? referrersCopy : refereesCopy;
  };

  const errorCopy = getErrorCopy();

  return (
    <Modal
      sectioned
      title="Warning"
      open={isModalOpen}
      onClose={closeModal}
      primaryAction={{
        content: "Continue",
        onAction() {
          noawait(() => modalPayload.actions.save());
          closeModal();
        },
      }}
      secondaryActions={[{ content: "Cancel", destructive: true, onAction: closeModal }]}
    >
      <Banner status="warning">
        <Text as="span" variant="bodyMd">
          Your current configuration will reward {errorCopy} after a successful referral. Do you want to continue?
        </Text>
      </Banner>
    </Modal>
  );
};
