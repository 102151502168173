import { Card, Checkbox, LegacyStack, Text } from "@shopify/polaris";
import { prefixClassName } from "@smartrr/shared/components/primitives";

import { LoyaltyStoreAccess } from "../../store";
import { Divider } from "rsuite";

export const RewardSettingsCard = () => {
  const settings = LoyaltyStoreAccess.useValue("rewardSettings");
  const actions = LoyaltyStoreAccess.useActions();

  return (
    <Card>
      <LegacyStack vertical spacing="extraTight">
        <Text as="h3" variant="headingMd" fontWeight="semibold">
          Single reward usage
        </Text>
        <Checkbox
          id={prefixClassName("global-loyalty-settings-limit-use")}
          label="Limit to one use per order"
          checked={settings.limitUse > 0}
          onChange={newValue => {
            actions.settings.update({
              ...settings,
              limitUse: newValue ? 1 : 0,
            });
          }}
        />
      </LegacyStack>
      <Divider />
      <LegacyStack vertical spacing="extraTight">
        <Text as="h3" variant="headingMd" fontWeight="semibold">
          Stackable discounts
        </Text>
        <Checkbox
          id={prefixClassName("global-loyalty-settings-stackable-discounts")}
          label="Allows customers to combine other discounts (product, order and shipping)"
          checked={settings.stackableDiscountCodes}
          onChange={newValue => {
            actions.settings.update({
              ...settings,
              stackableDiscountCodes: newValue,
            });
          }}
        />
      </LegacyStack>
    </Card>
  );
};
