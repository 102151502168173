import { Modal, Text } from "@shopify/polaris";
import { pluralize } from "@smartrr/shared/utils/pluralize";
import { LoyaltyStoreAccess } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store";
import { createSmartrrModal } from "@vendor-app/utils/createModalStore";
import React, { useCallback } from "react";

interface BulkDeleteRewardModalProps {
  ids: string[];
}

export const useBulkDeleteRewardModalStore = createSmartrrModal<BulkDeleteRewardModalProps>();

export const BulkDeleteRewardModal = () => {
  const { modalPayload } = useBulkDeleteRewardModalStore();

  if (!modalPayload?.ids) {
    return <React.Fragment />;
  }

  return <BulkDeleteRewardModalInner ids={modalPayload.ids} />;
};

const BulkDeleteRewardModalInner = ({ ids }: { ids: string[] }) => {
  const { closeModal, isModalOpen } = useBulkDeleteRewardModalStore();
  const actions = LoyaltyStoreAccess.useActions();

  const onConfirm = useCallback(() => {
    for (const id of ids) {
      actions.marketplace.findAndRemoveById(id);
    }
    closeModal();
  }, [ids]);

  return (
    <Modal
      open={isModalOpen}
      onClose={closeModal}
      title={
        <Text as="p" variant="headingMd">
          Are you sure you want to delete {pluralize(ids.length, "reward")}?
        </Text>
      }
      secondaryActions={[
        {
          content: "Back",
          onAction: closeModal,
        },
      ]}
      primaryAction={{
        content: "Confirm",
        onAction: onConfirm,
        destructive: true,
      }}
    >
      <Modal.Section>This action cannot be undone</Modal.Section>
    </Modal>
  );
};
