import { TextField } from "@shopify/polaris";
import { LoyaltyApi } from "@smartrr/shared/interfaces/loyalty/api";
import { LoyaltyValidation } from "@smartrr/shared/interfaces/loyalty/validation";
import { LoyaltyStoreAccess } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store";
import { validateError } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/libs/utils/validateError";

export const DiscountValueInput = ({
  value,
  type,
  onChange,
}: {
  value: string;
  type: LoyaltyApi.Enum.Discount.Type;
  onChange: (v: string) => void;
}) => {
  const { symbol: currencySymbol } = LoyaltyStoreAccess.useCurrency();
  return (
    <TextField
      label="Value"
      labelHidden
      prefix={type === "fixed_amount" ? currencySymbol : ""}
      suffix={type === "percentage" ? "%" : ""}
      value={String(Math.abs(Number(value)))}
      type="number"
      min={0}
      autoComplete="off"
      onChange={onChange}
      error={validateError(LoyaltyValidation.Incentive.schema.shape.priceRule.shape.value, value, true)}
    />
  );
};
