import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { useLoyaltyProductModalStore } from ".";
import React, { useCallback, useMemo, useState } from "react";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { MAX_PAGE_SIZE } from "@smartrr/shared/utils/paginatedQuery";
import { Modal, OptionList, Pagination, Text, TextField, Thumbnail } from "@shopify/polaris";
import { PaginationContainer } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";
import { FixedSearch } from "@vendor-app/app/_sharedComponents/BrowseProductsModal/components";
import { getPurchasableImage } from "@smartrr/shared/utils/useVariantToPurchasableMap";
import { isDefaultVariant } from "@smartrr/shared/utils/isDefaultVariant";
import { LoyaltyStoreAccess } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import styled from "styled-components";
import { RewardOptionListLabel } from "../components/RewardOptionListLabel";

const OptionListWrapper = styled.div`
  .Polaris-OptionList-Option button:disabled {
    background-color: rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
  }

  [data-polaris-tooltip-activator="true"] {
    width: 100%;
  }
`;

export const SelectingRewardProductModal = ({
  setSelectedIdToEdit,
}: {
  setSelectedIdToEdit: (vntShopifyId: string) => void;
}) => {
  const { isModalOpen: isOpen, closeModal } = useLoyaltyProductModalStore(state => state);
  // TODO: Migrate away from using redux based purchasable fetching
  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);
  const { addToast } = useToast();
  const [selected, setSelected] = useState<string[]>([]);
  const rewardItems = LoyaltyStoreAccess.useRewardItems();

  const [filteredPurchasablesSearchText, setFilteredPurchasablesSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(0);

  const { totalPages, purchasablesToShow } = useMemo(() => {
    const purchasablesToShow = purchasables.filter(p => {
      if (filteredPurchasablesSearchText.length === 0) {
        return true;
      }

      return p.purchasableName.toLocaleLowerCase().includes(filteredPurchasablesSearchText.toLocaleLowerCase());
    });

    return {
      totalPages: Math.ceil(purchasablesToShow.length / MAX_PAGE_SIZE),
      purchasablesToShow,
    };
  }, [purchasables, filteredPurchasablesSearchText]);

  const onSelectRewardItemConfirmation = useCallback(() => {
    if (!selected) {
      addToast("Select an item", true);
      return;
    }

    setSelectedIdToEdit(selected[0]);
  }, [selected]);

  const areAllVariantsSelected = useMemo(() => {
    return (purchasable: IPurchasable) => {
      return (
        purchasable.vnts?.every(vnt =>
          rewardItems.some(item => item.rewardType === "PRODUCT" && item.variantId === vnt.id)
        ) ?? false
      );
    };
  }, [rewardItems]);

  return (
    <Modal
      title={
        <Text variant="headingLg" as="p" fontWeight="regular">
          Select reward item
        </Text>
      }
      open={isOpen}
      limitHeight
      onClose={closeModal}
      secondaryActions={[
        {
          content: "Back",
          onAction: closeModal,
        },
      ]}
      primaryAction={{
        content: "Next",
        onAction: onSelectRewardItemConfirmation,
        disabled: !selected.length,
      }}
      footer={
        <PaginationContainer>
          <Pagination
            hasNext={totalPages > pageNumber + 1}
            hasPrevious={pageNumber != 0}
            label={`Showing ${pageNumber + 1} of ${totalPages || 1}`}
            onNext={() => setPageNumber(prev => prev + 1)}
            onPrevious={() => setPageNumber(prev => prev - 1)}
          />
        </PaginationContainer>
      }
    >
      <FixedSearch>
        <Modal.Section>
          <TextField
            autoComplete="off"
            label=""
            placeholder="Search products"
            value={filteredPurchasablesSearchText}
            onChange={text => {
              setFilteredPurchasablesSearchText(text);
              setPageNumber(0);
            }}
          />
        </Modal.Section>
      </FixedSearch>
      <OptionListWrapper>
        <OptionList
          selected={selected}
          options={purchasablesToShow
            .slice(pageNumber * MAX_PAGE_SIZE, pageNumber * MAX_PAGE_SIZE + MAX_PAGE_SIZE)
            .sort()
            .map(purchasable => {
              const purchasableImage = getPurchasableImage(purchasable);
              const areAllVntsSelected = areAllVariantsSelected(purchasable);
              const variantNames = purchasable.vnts
                ?.filter(vnt => !isDefaultVariant(vnt.purchasableVariantName) && !vnt.isDraftOrArchived)
                .map(vnt => vnt.purchasableVariantName)
                .join(", ");
              return {
                label: (
                  <RewardOptionListLabel
                    allVariantsSelected={areAllVntsSelected}
                    purchasable={purchasable}
                    variantNames={variantNames}
                  />
                ),
                value: purchasable?.vnts?.[0]?.shopifyId ?? "",
                media: (
                  <React.Fragment>
                    {purchasableImage ? (
                      <Thumbnail
                        size="small"
                        source={purchasableImage}
                        alt={`${purchasable.purchasableName} Thumbnail Image`}
                      />
                    ) : (
                      <React.Fragment />
                    )}
                  </React.Fragment>
                ),
                disabled: areAllVntsSelected,
              };
            })}
          onChange={s => setSelected(s)}
        />
      </OptionListWrapper>
    </Modal>
  );
};
