import { Banner, Button, Text } from "@shopify/polaris";
import { LoyaltyStoreAccess } from "../../../store";
import { noawait } from "@smartrr/shared/utils/noawait";
import styled from "styled-components";

const LoyaltyPreviewContainer = styled.div`
  button {
    margin-top: 10px;
  }
`;

export const LoyaltyPreview = () => {
  const status = LoyaltyStoreAccess.useStatus();
  const actions = LoyaltyStoreAccess.useActions();

  if (status !== "PREVIEW") {
    return null;
  }

  return (
    <LoyaltyPreviewContainer className="smartrr-loyalty-preview">
      <Banner title="Preview mode on" status="info">
        <Text as="p" variant="bodyMd">
          Set restrictions based on tier by selecting a reward from the table below and editing it&rsquo;s
          availability.
        </Text>
        <Button onClick={() => noawait(() => actions.enable())}>Enable loyalty</Button>
      </Banner>
    </LoyaltyPreviewContainer>
  );
};
