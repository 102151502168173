import { isArray } from "lodash";

interface Match {
  indexes: number[];
  [key: string]: string | number | number[];
}

export const getDuplicatesFromArray = (arr: Array<Record<string, any>>, excludedKeys?: string[]): Match[] => {
  const matches: Match[] = [];
  const seen = new Map<string, number>();

  for (const [i, obj] of arr.entries()) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && !excludedKeys?.includes(key)) {
        const val = obj[key];

        if (!val || (isArray(val) && !val.length)) {
          continue;
        }
        const keyValPair = `${key}:${val}`;

        if (seen.has(keyValPair)) {
          matches.push({ indexes: [seen.get(keyValPair)!, i], [key]: val });
        } else {
          seen.set(keyValPair, i);
        }
      }
    }
  }
  return matches;
};
