import { TextField } from "@shopify/polaris";
import { LoyaltyValidation } from "@smartrr/shared/interfaces/loyalty/validation";
import { displayError } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/libs/utils/displayError";
import { LoyaltyStoreAccess } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store";

export const PointsNeededInput = ({ value, onChange }: { value: number; onChange: (v: string) => void }) => {
  const { singularUnit, pluralUnit } = LoyaltyStoreAccess.useRewardUnits();
  return (
    <TextField
      label="Points needed"
      suffix={value === 1 ? singularUnit : pluralUnit}
      value={String(value)}
      type="number"
      min={0}
      autoComplete="off"
      onChange={onChange}
      error={displayError(
        LoyaltyValidation.Incentive.schema.shape.costInPoints,
        value,
        true,
        "Value must be greater than zero"
      )}
    />
  );
};
