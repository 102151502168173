import { IndexTable, useIndexResourceState } from "@shopify/polaris";
import { LoyaltyStoreAccess } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store";
import { useEffect } from "react";
import { LoyaltyRewardsTableEmptyState } from "./LoyaltyRewardsTableEmptyState";
import { LoyaltyRewardItemRows } from "./LoyaltyRewardItemRows";
import { useLoyaltyRewardsTableHeadings } from "./hooks/useTableHeadings";
import { LoyaltyRewardsTableAccess } from "./store";
import { useLoyaltyRewardsBulkActions } from "./hooks/useLoyaltyRewardsBulkActions";

export const LoyaltyRewardsTable = () => {
  const rewardItems = LoyaltyStoreAccess.useRewardItems();
  const { selectedResources, allResourcesSelected, handleSelectionChange, clearSelection } =
    useIndexResourceState(rewardItems);
  const headings = useLoyaltyRewardsTableHeadings();
  const actions = LoyaltyRewardsTableAccess.useActions();
  const { promotedBulkActions, bulkActions } = useLoyaltyRewardsBulkActions();

  useEffect(() => {
    actions.data.updateSelectionById(selectedResources);
  }, [selectedResources]);

  useEffect(() => {
    clearSelection();
  }, [rewardItems.length]);

  // Init table
  useEffect(() => {
    actions.data.update(async () => ({
      data: rewardItems,
      pagination: {
        maxPages: 1,
        pageNumber: 1,
      },
    }));
  }, [rewardItems]);

  return (
    <IndexTable
      itemCount={rewardItems.length}
      emptyState={<LoyaltyRewardsTableEmptyState />}
      selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
      onSelectionChange={handleSelectionChange}
      headings={headings}
      bulkActions={bulkActions}
      promotedBulkActions={promotedBulkActions}
    >
      <LoyaltyRewardItemRows />
    </IndexTable>
  );
};
