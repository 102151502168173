import { InteractiveTable } from "@vendor-app/app/_sharedComponents/InteractiveDataTable";
import { z } from "zod";

export namespace LoyaltyRewardsTableSorts {
  export const types = ["name", "visibility", "status", "points", "restrictions"] as const;
  export const schema = z.enum(types);
  export type Sort = z.infer<typeof schema>;

  export type SelectedSort = InteractiveTable.SelectedSort & {
    sortType: Sort;
  };
}
