import { Page } from "@shopify/polaris";
import React from "react";

import { VerifyLoyaltyEnabled } from "./components/VerifyLoyaltyEnabled";
export const AdminLoyaltyRoute = (): JSX.Element => {
  return (
    <React.Fragment>
      <Page narrowWidth title="Loyalty">
        <VerifyLoyaltyEnabled></VerifyLoyaltyEnabled>
      </Page>
    </React.Fragment>
  );
};
