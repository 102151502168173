import { Select } from "@shopify/polaris";
import { useCallback, useState } from "react";
import { LoyaltyApi } from "@smartrr/shared/interfaces/loyalty/api";

interface DiscountTypeSelectProps {
  value: LoyaltyApi.Enum.Discount.Type;
  onChange: (v: LoyaltyApi.Enum.Discount.Type) => void;
}

export const DiscountTypeSelect = ({ value, onChange }: DiscountTypeSelectProps) => {
  const [selectedValue, setSelectedValue] = useState<LoyaltyApi.Enum.Discount.Type>(value);
  const handleSelectChange = useCallback((newValue: LoyaltyApi.Enum.Discount.Type) => {
    setSelectedValue(newValue);
    onChange(newValue);
  }, []);

  const options = [
    { label: "Percentage", value: "percentage" },
    { label: "Fixed amount", value: "fixed_amount" },
  ];
  return <Select label="Type" value={selectedValue} onChange={handleSelectChange} options={options} />;
};
