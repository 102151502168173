import { Card, HorizontalStack, Text, VerticalStack } from "@shopify/polaris";
import { ToggleSwitch } from "../../../ToggleSwitch";
import { LoyaltyStoreAccess } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store";

export const LoyaltyTiersStatus = () => {
  const actions = LoyaltyStoreAccess.useActions();
  const { tiersEnabled } = LoyaltyStoreAccess.useInput();
  const savedValues = LoyaltyStoreAccess.useSavedValues();

  return (
    <Card>
      <HorizontalStack wrap={false}>
        <VerticalStack gap="1">
          <Text as="h3" variant="headingSm">
            Status
          </Text>
          <Text as="span" variant="bodyMd" color="subdued">
            {
              "Turn on to enable loyalty tiers for your customers. If your loyalty program is live, you won't be able to turn tiers off once you save."
            }
          </Text>
        </VerticalStack>
        <ToggleSwitch
          defaultToggled={tiersEnabled}
          disabled={savedValues.tiersEnabled}
          onHandleChangeCB={() => actions.tiers.toggle()}
        />
      </HorizontalStack>
    </Card>
  );
};
