import React from "react";
import { Modal } from "@shopify/polaris";
import { createSmartrrModal } from "@vendor-app/utils/createModalStore";
import { LoyaltyTiersConfirmationModalInner } from "./LoyaltyTiersConfirmationModalInner";
import { useConfirmTiersEnabled } from "../../../libs/hooks/useConfirmTiersEnabled";

export const useTiersConfirmationModalStore = createSmartrrModal<{
  values: {
    rewardsItemHasSubscriberOnly: boolean;
    otpEnabled: boolean;
  };
}>();

export const LoyaltyTiersConfirmationModal = () => {
  const { closeModal, isModalOpen, modalPayload } = useTiersConfirmationModalStore();
  const confirmTiersEnabled = useConfirmTiersEnabled();

  if (!modalPayload) {
    return <React.Fragment />;
  }

  return (
    <Modal
      sectioned
      title="Loyalty changes to enable tiers"
      open={isModalOpen}
      onClose={closeModal}
      primaryAction={{
        content: "Confirm",
        async onAction() {
          await confirmTiersEnabled();
          closeModal();
        },
      }}
      secondaryActions={[{ content: "Cancel", destructive: false, onAction: closeModal }]}
    >
      <LoyaltyTiersConfirmationModalInner
        rewardItemHasSubscriberOnly={modalPayload.values.rewardsItemHasSubscriberOnly}
        otpEnabled={modalPayload.values.otpEnabled}
      />
    </Modal>
  );
};
