import { ZodSchema } from "zod";

export const validateError = <Type>(schema: ZodSchema, value: Type, showError: boolean) => {
  if (!showError) {
    return undefined;
  }
  const result = schema.safeParse(value);
  if (result.success) {
    return undefined;
  }
  if (result.error.issues.length === 0) {
    return undefined;
  }
  return true;
};
