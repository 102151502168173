import React from "react";
import { Icon, Text } from "@shopify/polaris";
import { DiamondAlertMajor } from "@shopify/polaris-icons";
import styled from "styled-components";

interface ErrorMessageWithIconProps {
  message: string;
  justifyContent?: string;
}

interface ErrorMessageWithIconWrapperProps {
  justifyContent?: string;
}

const ErrorMessageWithIconWrapper = styled.div<ErrorMessageWithIconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || "flex-end"};
  gap: 5px;
  width: 100%;

  .Polaris-Icon {
    margin: 0;
  }

  .Polaris-Text--root {
    width: 40%;
  }

  @media screen and (min-width: 600px) {
    width: 100%;

    .Polaris-Text--root {
      width: unset;
    }
  }

  @media screen and (min-width: 601px) and (max-width: 767px) {
    width: 92%;
  }

  @media screen and (min-width: 768px) {
    width: 95%;

    .Polaris-Text--root {
      width: unset;
    }
  }
`;

export const ErrorMessageWithIcon = ({ message, justifyContent }: ErrorMessageWithIconProps) => {
  return (
    <ErrorMessageWithIconWrapper justifyContent={justifyContent}>
      <Icon source={DiamondAlertMajor} color="critical" />
      <Text as="span" variant="bodyMd" color="critical">
        {message}
      </Text>
    </ErrorMessageWithIconWrapper>
  );
};
