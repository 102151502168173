import { LoyaltyStoreAccess } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store";
import { LoyaltyForm } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store/form";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import React, { useCallback, useMemo, useState } from "react";
import { generateNewRewardProduct, generateVariantSelectOptions, setTiersForProducts } from "../utils";
import { formatProductAndVariantNameForDisplay } from "@smartrr/shared/utils/displayUtils";
import { HorizontalStack, Select, Thumbnail, VerticalStack, Modal, Text } from "@shopify/polaris";
import { InputSideBySideWrapper } from "../constants";
import { AvailableToSelect } from "../components/AvailableToSelect";
import { LoyaltySelection } from "../../../LoyaltyOTP";
import { PointsNeededInput } from "../components/PointsNeededInput";
import { useLoyaltyProductModalStore } from ".";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { useVariantShopifyIDToPurchasableMap } from "@smartrr/shared/utils/useVariantToPurchasableMap";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import { validateDataWithZodSchema } from "@smartrr/shared/utils/validateDataWithZodSchema";
import { LoyaltyValidation } from "@smartrr/shared/interfaces/loyalty/validation";
import { truncate } from "lodash";
import { isVariantInStock } from "@smartrr/shared/utils/variants";
import { VisibilityCheckbox } from "../components/VisibilityCheckbox";

export const EditingRewardProductModal = ({
  selectedIdToEdit,
  clearSelectedIdToEdit,
}: {
  selectedIdToEdit: string;
  clearSelectedIdToEdit: () => void;
}) => {
  const { modalPayload: payload } = useLoyaltyProductModalStore(state => state);
  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);
  const targetShopifyVariantId = selectedIdToEdit ?? payload?.product?.variant.shopifyId;
  const variantShopifyIdToPurchasableMap = useVariantShopifyIDToPurchasableMap(purchasables);
  const existingRewardProducts = LoyaltyStoreAccess.useValue("variants");
  const tiersEnabled = LoyaltyStoreAccess.useIsTiersEnabled();

  const purchaseable = useMemo(
    () => variantShopifyIdToPurchasableMap[shopifyGidToNumber(targetShopifyVariantId)],
    [selectedIdToEdit, payload?.product?.shopifyId]
  );

  // Formatting new reward or setting pre-existing
  const originalRewardItem: LoyaltyForm.RewardProduct.Type | undefined = useMemo(() => {
    // If pre-existing
    if (payload?.product) {
      // finding product from list of reward items as to fetch most up to date version instead of relying stale info passed in
      return existingRewardProducts.find(item => item.shopifyId === targetShopifyVariantId);
    }

    // If being newly created, select first variant as default
    if (!purchaseable) {
      return;
    }
    // Looking for first available variant that isn't already selected and falling back to first variant in purchasable
    const variant =
      purchaseable.vnts?.find(vnt =>
        existingRewardProducts.every(existingReward => existingReward.variantId !== vnt.id)
      ) ?? purchaseable.vnts?.[0];

    if (!variant) {
      return;
    }

    return generateNewRewardProduct(variant, purchaseable, tiersEnabled);
  }, [payload?.product]);

  if (!purchaseable || !originalRewardItem) {
    return <React.Fragment />;
  }
  return (
    <EditingRewardProductModalInner
      purchaseable={purchaseable}
      originalRewardItem={originalRewardItem}
      clearSelectedIdToEdit={clearSelectedIdToEdit}
    />
  );
};

const EditingRewardProductModalInner = ({
  purchaseable,
  originalRewardItem,
  clearSelectedIdToEdit,
}: {
  originalRewardItem: LoyaltyForm.RewardProduct.Type;
  purchaseable: IPurchasable;
  clearSelectedIdToEdit: () => void;
}) => {
  const [rewardItem, setRewardItem] = useState<LoyaltyForm.RewardProduct.Type>(originalRewardItem);
  const { modalPayload: payload, isModalOpen: isOpen, closeModal } = useLoyaltyProductModalStore(state => state);
  const actions = LoyaltyStoreAccess.useActions();
  const selectedVariantShopifyIds = LoyaltyStoreAccess.useValue("variants").map(vnt => vnt.shopifyId);
  const { tiers } = LoyaltyStoreAccess.useInput();
  const tiersEnabled = LoyaltyStoreAccess.useIsTiersEnabled();
  const { addToast } = useToast();
  const isEditing = useMemo(() => !!payload?.product, [payload?.product]);

  const isVariantOutOfStock = useMemo(() => {
    const variant = purchaseable.vnts?.find(vnt => vnt.shopifyId === rewardItem.variant.shopifyId);
    return variant ? !isVariantInStock(variant) : false;
  }, [rewardItem]);

  const isDraftOrArchived = useMemo(() => {
    const variant = purchaseable.vnts?.find(vnt => vnt.shopifyId === rewardItem.variant.shopifyId);
    return purchaseable.isDraftOrArchived || variant?.isDraftOrArchived;
  }, [rewardItem, purchaseable]);

  // Need to validate before being passed to api
  const hasError = useMemo(() => {
    const response = validateDataWithZodSchema(LoyaltyValidation.Variant.schema, rewardItem);
    return !response.success;
  }, [rewardItem]);

  const displayName = useMemo(
    () =>
      formatProductAndVariantNameForDisplay({
        purchasableName: truncate(rewardItem.variant.productName),
        purchasableVariantName: truncate(rewardItem.variant.variantName),
      }),
    [rewardItem]
  );

  const variantSelectOptions = useMemo(
    () => generateVariantSelectOptions(purchaseable.vnts ?? [], selectedVariantShopifyIds),
    [purchaseable.vnts, selectedVariantShopifyIds]
  );

  const setSelectedVariant = useCallback((selectedVariantId: string) => {
    const variant = purchaseable.vnts?.find(vnt => vnt.shopifyId === selectedVariantId);
    if (variant) {
      setRewardItem(prev => ({
        ...prev!,
        variantId: variant.id,
        variant: {
          id: variant.id,
          productName: purchaseable.purchasableName,
          shopifyId: variant.shopifyId!,
          variantImages: [variant.purchasableVariantImages?.[0] ?? purchaseable.purchasableImages?.[0] ?? ""],
          variantName: variant.purchasableVariantName ?? "",
        },
      }));
    }
  }, []);

  const onConfirm = () => {
    if (payload?.product) {
      actions.marketplace.product.updateById(payload.product.variantId, rewardItem);
    } else if (selectedVariantShopifyIds.includes(rewardItem.variant.shopifyId)) {
      addToast("Variant already exists", true);
      return;
    } else {
      actions.marketplace.product.add(rewardItem);
    }
    closeModal();
  };

  const onRemove = () => {
    if (payload?.product) {
      actions.marketplace.product.removeById(payload.product.id);
      closeModal();
    }
  };

  const availableToValue = useMemo(() => {
    if (tiersEnabled && rewardItem.tiers.length) {
      const lowestTier = rewardItem.tiers.reduce((prev, curr) => (prev.tierRank < curr.tierRank ? prev : curr));
      return String(lowestTier.tierRank);
    }

    if (tiersEnabled && tiers.length) {
      return String(tiers[0].tierRank);
    }

    return rewardItem.subscriberOnly ? LoyaltySelection.subscribers : LoyaltySelection.all;
  }, [tiers, rewardItem]);

  return (
    <Modal
      title={
        <HorizontalStack>
          <HorizontalStack align="center" gap={"2"}>
            <Thumbnail
              size="extraSmall"
              source={rewardItem.variant.variantImages[0]}
              alt={`${displayName} Thumbnail image`}
            />
            <Text variant="headingLg" as="p" fontWeight="regular">
              {displayName}
            </Text>
          </HorizontalStack>
        </HorizontalStack>
      }
      open={isOpen}
      limitHeight
      onClose={closeModal}
      secondaryActions={[
        {
          content: isEditing ? "Delete reward" : "Back",
          onAction: isEditing ? onRemove : clearSelectedIdToEdit,
          destructive: isEditing,
        },
      ]}
      primaryAction={{
        content: "Confirm",
        onAction: onConfirm,
        disabled: hasError,
      }}
    >
      <Modal.Section>
        <VerticalStack gap={"4"}>
          <InputSideBySideWrapper>
            {variantSelectOptions.length ? (
              <Select
                label="Variant"
                options={variantSelectOptions}
                onChange={setSelectedVariant}
                value={rewardItem.variant.shopifyId}
              />
            ) : (
              <React.Fragment />
            )}
            <PointsNeededInput
              value={rewardItem.costInPoints}
              onChange={v =>
                setRewardItem(prev => ({
                  ...prev!,
                  costInPoints: Number(v),
                }))
              }
            />
          </InputSideBySideWrapper>
          <AvailableToSelect
            onChange={v => {
              setRewardItem(prev => ({
                ...prev!,
                subscriberOnly: v === LoyaltySelection.subscribers,
                tiers: setTiersForProducts(Number(v), tiers, rewardItem, actions.tiers.update),
              }));
            }}
            value={availableToValue}
          />
          <VisibilityCheckbox
            onChange={() =>
              setRewardItem(prev => ({
                ...prev!,
                hidden: !prev.hidden,
              }))
            }
            value={!rewardItem.hidden}
            isDraftOrArchived={isDraftOrArchived}
            isVariantOutOfStock={isVariantOutOfStock}
          />
        </VerticalStack>
      </Modal.Section>
    </Modal>
  );
};
