import { useCallback } from "react";
import { useSwitchRewardItemsToTiers } from "./useSwitchRewardItemsToTiers";
import { LoyaltyStoreAccess } from "../../store";

export const useConfirmTiersEnabled = () => {
  const { save } = LoyaltyStoreAccess.useActions();
  const { otpEnabled } = LoyaltyStoreAccess.useInput();
  const { update: updateOtp, enable: enableOtp } = LoyaltyStoreAccess.useActions().otp;
  const switchRewardItemsToTiers = useSwitchRewardItemsToTiers();

  return useCallback(async () => {
    switchRewardItemsToTiers();
    await save();

    if (!otpEnabled) {
      updateOtp(true);
      await enableOtp();
    }
  }, []);
};
