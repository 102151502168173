import { InteractiveTable } from "@vendor-app/app/_sharedComponents/InteractiveDataTable";
import { TypedTableColumnType } from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";
import { z } from "zod";

export namespace LoyaltyRewardsTableColumns {
  export const types = ["image", "item", "visibility", "points", "restrictions"] as const;
  export const typeSchema = z.enum(types);
  export type Column = z.infer<typeof typeSchema>;

  export type SelectedColumn = InteractiveTable.SelectedColumn & {
    columnType: Column;
  };

  export const availableColumns: TypedTableColumnType<Column> = {
    image: {
      name: "Image",
      paginatedValue: "image",
      filtering: false,
      disabled: true,
      sorting: false,
    },
    item: {
      name: "Item",
      paginatedValue: "item",
      filtering: true,
      disabled: false,
      sorting: true,
    },
    visibility: {
      name: "Visibility",
      paginatedValue: "visibility",
      filtering: true,
      disabled: false,
      sorting: true,
    },
    points: { name: "Points", paginatedValue: "points", filtering: true, disabled: false, sorting: true },
    restrictions: {
      name: "Availability",
      paginatedValue: "availability",
      filtering: true,
      disabled: false,
      sorting: true,
    },
  };
}
