import { Text } from "@shopify/polaris";
import { CurrencyCode } from "@smartrr/shared/currencyCode";
import React, { useMemo } from "react";
import styled from "styled-components";
import { currencyNames } from "@smartrr/shared/utils/currencyNames";
import { LoyaltyStoreAccess } from "../../../../store";
import { NumberedTextField } from "@vendor-app/app/_sharedComponents/NumberedTextField";
import { displayError } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/libs/utils/displayError";
import { LoyaltyApi } from "@smartrr/shared/interfaces/loyalty/api";

interface CurrencyItemProps {
  code: CurrencyCode;
  isShopCurrency: boolean;
}

const LoyaltyCurrencyItemInfo = styled.div`
  margin: 0;

  display: flex;
  gap: 20px;

  background: var(--Color-checkbox-bg-surface-disabled, #00000014);
  height: 2.25rem;

  align-items: center;
  padding-left: 20px;

  border-radius: 4px;

  .smartrr-currency-info-code {
    width: 32px;
  }
`;

const LoyaltyCurrencyItemInput = styled.div`
  .Polaris-TextField--disabled {
    padding-right: calc(1.375rem + 0.125rem + var(--p-border-width-025));
  }
`;

const LoyaltyItemContainer = styled.div`
  display: flex;

  justify-content: space-between;

  > div:nth-of-type(1) {
    width: 58%;
  }

  > div:nth-of-type(2) {
    width: 38%;

    > .Polaris-LegacyStack {
      margin: 0;

      > .Polaris-LegacyStack__Item {
        margin: 0;
      }
    }
  }
`;

export const LoyaltyCurrencyItem = ({ code, isShopCurrency }: CurrencyItemProps) => {
  const actions = LoyaltyStoreAccess.useActions();
  const multiplier = LoyaltyStoreAccess.useCurrencyMultiplierValue(code);
  const showError = LoyaltyStoreAccess.useShowError();

  const currencyInfo = useMemo(() => {
    return currencyNames.find(name => name.code === code);
  }, [code]);

  if (!currencyInfo) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <LoyaltyItemContainer>
      <LoyaltyCurrencyItemInfo>
        <div className={"smartrr-currency-info-flag"}>
          <img src={`/currency_flags/${currencyInfo.code.toLowerCase()}.svg`} height={"16px"} />
        </div>
        <div className={"smartrr-currency-info-code"}>
          <Text as="p" variant="bodyMd" color="subdued">
            {currencyInfo.code}
          </Text>
        </div>
        <div className={"smartrr-currency-info-name"}>
          <Text as="p" variant="bodyMd" color="subdued">
            {currencyInfo.name}
          </Text>
        </div>
      </LoyaltyCurrencyItemInfo>
      <LoyaltyCurrencyItemInput>
        <NumberedTextField
          label="Currency multiplier"
          labelHidden
          autoComplete="off"
          type="number"
          align="left"
          value={multiplier}
          disabled={isShopCurrency}
          onChange={val => actions.currencyMultiplier.updateCurrencyMultipler(code, Number(val))}
          error={displayError(LoyaltyApi.CurrencyMultiplier.schema.shape.multiplier, multiplier, true)}
        />
      </LoyaltyCurrencyItemInput>
    </LoyaltyItemContainer>
  );
};
