import { LoyaltyStoreAccess } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store";
import { rewardAvailabilitySelectOptions } from "../constants";
import { HorizontalStack, Icon, Select, Text, Tooltip } from "@shopify/polaris";
import { CircleInformationMajor } from "@shopify/polaris-icons";
import React, { useMemo } from "react";
import { getTierOptions } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/libs/utils/getTierOptions";

export const AvailableToSelect = ({ value, onChange }: { value: string; onChange: (v: string) => void }) => {
  const isLoyaltyAvailableToAll = LoyaltyStoreAccess.useValue("otpEnabled");
  const { tiers, tiersEnabled } = LoyaltyStoreAccess.useInput();

  const options = useMemo(() => {
    if (tiersEnabled && tiers.length) {
      return getTierOptions(tiers);
    }

    return isLoyaltyAvailableToAll ? rewardAvailabilitySelectOptions : [rewardAvailabilitySelectOptions[1]];
  }, [isLoyaltyAvailableToAll, tiers, tiersEnabled]);
  return (
    <Select
      label={
        <HorizontalStack gap={"150"}>
          <Text as="p" variant="bodyMd">
            Available to
          </Text>
          {isLoyaltyAvailableToAll ? (
            <React.Fragment />
          ) : (
            <Tooltip content="Your loyalty program is currently only available for Subscribers">
              <Icon source={CircleInformationMajor} color="subdued" />
            </Tooltip>
          )}
        </HorizontalStack>
      }
      options={options}
      onChange={onChange}
      value={value}
      disabled={!isLoyaltyAvailableToAll}
    />
  );
};
