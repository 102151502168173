/**
 * Checks an array of objects for any empty, null, or undefined values
 * Can pass in an array excluded keys as a second parameter
 */
export const checkForEmptyValues = (arr: {}[], excludedKeys: string[], excludedIndexes?: number[]): boolean => {
  return arr.some((obj, index) => {
    if (excludedIndexes?.includes(index)) {
      return false;
    }
    return Object.entries(obj).some(([key, value]) => {
      if (excludedKeys.includes(key)) {
        return false;
      }
      return value === "" || (value as number) <= 0 || value === null || value === undefined;
    });
  });
};
