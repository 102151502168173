import { Card, ChoiceList, Text, TextField, VerticalStack } from "@shopify/polaris";
import { LoyaltyApi } from "@smartrr/shared/interfaces/loyalty/api";
import { LoyaltyValidation } from "@smartrr/shared/interfaces/loyalty/validation";
import { LoyaltyStoreAccess } from "../../../store";
import { discountNamesBasedOnType } from "../../../libs/utils/constants";
import { displayError } from "../../../libs/utils/displayError";
import { ToggleSwitch } from "../../ToggleSwitch";

const LoyaltyReferralHeader = () => {
  const referral = LoyaltyStoreAccess.useValue("referralProgram");
  const actions = LoyaltyStoreAccess.useActions();

  return (
    <div
      style={{
        display: "flex",
        marginBottom: "12px",
      }}
    >
      <VerticalStack gap="1">
        <Text as="p" variant="headingSm">
          Referral program
        </Text>
        <Text as="span" variant="bodyMd" color="subdued">
          Give customers the ability to easily refer friends and earn incentives once the referred customer makes
          their first purchase.
        </Text>
      </VerticalStack>
      <ToggleSwitch defaultToggled={!!referral} onHandleChangeCB={() => actions.referral.toggle()} />
    </div>
  );
};

export const LoyaltyReferral = () => {
  const referral = LoyaltyStoreAccess.useValue("referralProgram");
  const actions = LoyaltyStoreAccess.useActions();
  const showError = LoyaltyStoreAccess.useShowError();
  const { symbol: currencySymbol } = LoyaltyStoreAccess.useCurrency();

  if (!referral) {
    return (
      <Card>
        <LoyaltyReferralHeader />
      </Card>
    );
  }

  return (
    <Card>
      <VerticalStack gap={"8"}>
        <div>
          <VerticalStack gap={"6"}>
            <LoyaltyReferralHeader />
            <VerticalStack gap={"3"}>
              <TextField
                label="Referral program name"
                placeholder="Example: Give 10%, get 100 points"
                value={referral.name}
                autoComplete="off"
                onChange={s => {
                  actions.referral.update({
                    ...referral,
                    name: s,
                  });
                }}
                error={displayError(LoyaltyValidation.Referral.schema.shape.name, referral.name, showError)}
              />
              <TextField
                label="Referral program description"
                placeholder="Example: Get 100 points each time a friend uses your referral link on their purchase. Bonus—they'll get 10% off their first order, too!"
                value={String(referral.description)}
                autoComplete="off"
                multiline={2}
                showCharacterCount
                maxLength={150}
                onChange={s => {
                  actions.referral.update({
                    ...referral,
                    description: s,
                  });
                }}
                error={displayError(
                  LoyaltyValidation.Referral.schema.shape.description,
                  referral.description,
                  showError
                )}
              />
            </VerticalStack>
          </VerticalStack>
        </div>

        <VerticalStack gap={"8"}>
          <VerticalStack gap={"3"}>
            <VerticalStack gap={"1"}>
              <Text as="span" variant="headingXs" fontWeight="semibold">
                REFERRER INCENTIVE
              </Text>
              <Text as="span" variant="bodyMd" color="subdued">
                (&quot;You&quot;) The individual who shares their custom referral link
              </Text>
              <ChoiceList
                choices={[
                  {
                    label: "Points",
                    value: "points",
                    disabled: true,
                  },
                ]}
                selected={["points"]}
                title="Choose type of referral reward"
                titleHidden
              ></ChoiceList>
              <TextField
                label="Points"
                labelHidden
                value={String(referral.pointsInReward)}
                type="number"
                min={0}
                autoComplete="off"
                onChange={s => {
                  actions.referral.update({
                    ...referral,
                    pointsInReward: Number(s),
                  });
                }}
                error={displayError(
                  LoyaltyValidation.Referral.schema.shape.pointsInReward,
                  referral.pointsInReward,
                  showError
                )}
              />
            </VerticalStack>
          </VerticalStack>

          <VerticalStack gap={"3"}>
            <VerticalStack gap={"1"}>
              <Text as="span" variant="headingXs" fontWeight="semibold">
                REFEREE INCENTIVE
              </Text>
              <Text as="span" variant="bodyMd" color="subdued">
                (&quot;Your friend&quot;) The individual who makes a purchase with a referral link
              </Text>
            </VerticalStack>
            <ChoiceList
              choices={LoyaltyApi.Enum.Discount.arr.map(type => ({
                label: discountNamesBasedOnType[type],
                value: type,
              }))}
              selected={[referral.priceRule.type]}
              title="Choose type of referral reward"
              titleHidden
              onChange={s => {
                if (s.length && LoyaltyApi.Enum.Discount.arr.includes(s[0] as LoyaltyApi.Enum.Discount.Type)) {
                  actions.referral.update({
                    ...referral,
                    priceRule: {
                      ...referral.priceRule,
                      type: s[0] as LoyaltyApi.Enum.Discount.Type,
                    },
                  });
                }
              }}
            ></ChoiceList>
            <TextField
              label="Amount"
              labelHidden
              value={String(Math.abs(Number(referral.priceRule.value)))}
              type="number"
              min={0}
              autoComplete="off"
              prefix={referral.priceRule.type === "fixed_amount" ? currencySymbol : "%"}
              onChange={s => {
                actions.referral.update({
                  ...referral,
                  priceRule: {
                    ...referral.priceRule,
                    value: String(Number(s) * -1),
                  },
                });
              }}
              error={displayError(
                LoyaltyValidation.Referral.schema.shape.priceRule.shape.value,
                referral.priceRule.value,
                showError,
                "Value must be greater than or equal to 0"
              )}
            />
          </VerticalStack>
        </VerticalStack>
      </VerticalStack>
    </Card>
  );
};
