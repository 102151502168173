import { Button, Card, HorizontalStack, Icon, Text, VerticalStack } from "@shopify/polaris";
import { LockMajor } from "@shopify/polaris-icons";
import { adminRoutePrefix } from "@smartrr/shared/constants";
import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import React from "react";

import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { isFeatureAvailable } from "@vendor-app/app/AdminRoute/components/authorization/featureAccess";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";

import LoyaltyImage from "./LoyaltyNotAvailable.jpg";
import { FetchLoyalty } from "../FetchLoyalty";

export const VerifyLoyaltyEnabled = () => {
  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const user = useSmartrrVendorSelector(state => state.auth.user);

  if (!isFeatureAvailable(FeatureEnum.LOYALTY, user, activePlan)) {
    return (
      <Card>
        <HorizontalStack wrap={false}>
          <img src={LoyaltyImage} width="232" height="226" alt="Not available" />
          <VerticalStack align="space-evenly">
            <HorizontalStack>
              <Icon source={LockMajor} />
              <Text variant="headingMd" as="h2">
                Loyalty unavailable on current plan
              </Text>
            </HorizontalStack>
            <Text as="p" variant="bodyMd">
              Upgrade to Excel to incentivize your customers to earn and redeem rewards through different shop
              touchpoints—including referrals.
            </Text>
            <HorizontalStack align="end">
              <Button primary onClick={() => navigateWithShopInQuery(`${adminRoutePrefix}/smartrr-account`)}>
                💎 Upgrade to Excel
              </Button>
            </HorizontalStack>
          </VerticalStack>
        </HorizontalStack>
      </Card>
    );
  }

  return <FetchLoyalty />;
};
