import { Card, Tabs, VerticalStack } from "@shopify/polaris";
import React, { useCallback, useMemo } from "react";
import { LoyaltyStoreAccess } from "../../store";
import { LoyaltySections } from "../../libs/utils/constants";
import { useFilteredLoyaltyTabs } from "../../libs/hooks/useFilteredLoyaltyTabs";
import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";

import { LoyaltyBonus } from "./LoyaltyBonus";
import { LoyaltyDescription } from "./LoyaltyDescription";
import { LoyaltyEvents } from "./LoyaltyEvents";
import { LoyaltyOTP } from "./LoyaltyOTP";
import { LoyaltyReferral } from "./LoyaltyReferral";
import { LoyaltySave } from "./LoyaltySave";
import { LoyaltyUnit } from "./LoyaltyUnit";
import { LoyaltyCurrency } from "./LoyaltyCurrency";
import { LoyaltyPreview } from "./LoyaltyPreview";
import { LoyaltyRewards } from "./LoyaltyRewards";
import { LoyaltyTiers } from "./LoyaltyTiers";
import { LoyaltyConfirmationModal } from "./LoyaltyConfirmationModal";
import styled from "styled-components";
import { LoyaltyTiersConfirmationModal } from "./LoyaltyTiersConfirmationModal";
import { LoyaltyTiersValidationModal } from "./LoyaltyTiersValidationModal";
import { LoyaltyAnalytics } from "./LoyaltyAnalytics";

const renderSwitch = (selectedTab: LoyaltySections, tabs: LoyaltySections[]) => {
  const selected = tabs[selectedTab];
  switch (selected) {
    case LoyaltySections.PROGRAM: {
      return (
        <VerticalStack gap={"5"}>
          <LoyaltyAnalytics />
          <LoyaltyOTP />
          <Card>
            <LoyaltyUnit />
          </Card>
          <Card>
            <LoyaltyDescription />
          </Card>
          <Card>
            <LoyaltyEvents />
          </Card>
          <LoyaltyBonus />
        </VerticalStack>
      );
    }
    case LoyaltySections.REWARDS: {
      return <LoyaltyRewards />;
    }
    case LoyaltySections.TIERS: {
      return <LoyaltyTiers />;
    }
    case LoyaltySections.REFERRALS: {
      return <LoyaltyReferral />;
    }
    case LoyaltySections.CURRENCIES: {
      return <LoyaltyCurrency />;
    }
    default: {
      return <React.Fragment />;
    }
  }
};

const StyledTabWrapper = styled.div`
  .Polaris-Box:has(.Polaris-Tabs) {
    padding: 0;
  }
`;

export const ShowLoyalty = (): JSX.Element => {
  const actions = LoyaltyStoreAccess.useActions();
  const selectedTab = LoyaltyStoreAccess.useSelectedTab();

  const handleTabChange = useCallback((selectedTabIndex: number) => {
    actions.tabs.update(selectedTabIndex);
  }, []);

  const filteredLoyaltyTabs = useFilteredLoyaltyTabs();
  const { tieredLoyalty: isTiersFeatureFlagEnabled } = useSmartrrFlags();

  const tabs = useMemo(
    () => [
      LoyaltySections.PROGRAM,
      LoyaltySections.REWARDS,
      ...(isTiersFeatureFlagEnabled ? [LoyaltySections.TIERS] : []),
      LoyaltySections.REFERRALS,
      LoyaltySections.CURRENCIES,
    ],
    [selectedTab, isTiersFeatureFlagEnabled, filteredLoyaltyTabs]
  );

  return (
    <React.Fragment>
      <LoyaltySave />
      <VerticalStack gap={"5"}>
        <LoyaltyPreview />
        <StyledTabWrapper>
          <Tabs tabs={filteredLoyaltyTabs} selected={selectedTab} onSelect={handleTabChange} fitted />
        </StyledTabWrapper>
        {renderSwitch(selectedTab, tabs)}
        <LoyaltyConfirmationModal />
        <LoyaltyTiersConfirmationModal />
        <LoyaltyTiersValidationModal />
      </VerticalStack>
    </React.Fragment>
  );
};
