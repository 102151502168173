import { TextField, TextFieldProps } from "@shopify/polaris";
import React, { useEffect, useState } from "react";

interface NumberedTextFieldProps extends Omit<TextFieldProps, "value"> {
  value: number;
}

/**
 * Allows using numbers as input to TextField while letting the user type decimals easily
 * eg: 0.002
 */
export const NumberedTextField = ({ value, onChange, ...props }: NumberedTextFieldProps) => {
  const [num, setNum] = useState(String(value));

  useEffect(() => {
    if (Number(num) !== value) {
      setNum(String(value));
    }
  }, [value]);

  useEffect(() => {
    onChange && onChange(num, "");
  }, [num]);

  return <TextField value={num} onChange={s => setNum(s)} {...props} />;
};
