import { Banner, Modal, Text } from "@shopify/polaris";
import { useState } from "react";

import { AlertTriangleIcon } from "@vendor-app/app/_sharedComponents/SVGs/AlertTriangleIcon";
import { LoyaltyStoreAccess } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store";
import { createSmartrrModal } from "@vendor-app/utils/createModalStore";

export const useLoyaltyOTPConfirmationModal = createSmartrrModal<{}>();

export const LoyaltyOTPConfirmationModal = () => {
  const isModalOpen = useLoyaltyOTPConfirmationModal(state => state.isModalOpen);
  const closeModal = useLoyaltyOTPConfirmationModal(state => state.closeModal);

  const [loading, setLoading] = useState(false);

  const actions = LoyaltyStoreAccess.useActions();

  const closeLoyaltyModal = () => {
    actions.otp.update(false);
    closeModal();
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={closeLoyaltyModal}
      title={"Important notice"}
      secondaryActions={[
        {
          content: "Go back",
          onAction: closeLoyaltyModal,
        },
      ]}
      primaryAction={{
        content: "Confirm",
        loading,
        onAction() {
          setLoading(true);
          actions.otp.enable().then(() => {
            setLoading(false);
            closeModal();
          });
        },
      }}
    >
      <Modal.Section>
        <Banner status="info" icon={AlertTriangleIcon}>
          <Text as="p" variant="bodyMd">
            Are you sure you want to change your loyalty availability from
            <Text as="span" variant="bodyMd" fontWeight="bold">
              &nbsp;Subscribers only&nbsp;
            </Text>
            to
            <Text as="span" variant="bodyMd" fontWeight="bold">
              &nbsp;All customers
            </Text>
            ? You won&apos;t be able to reverse this action.
          </Text>
        </Banner>
      </Modal.Section>
    </Modal>
  );
};
