import React, { useMemo, useState } from "react";
import { Banner, Text } from "@shopify/polaris";
import { CircleInformationMajor } from "@shopify/polaris-icons";
import { LoyaltyStoreAccess } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store";
import { useMyShopifyDomainSelector } from "@vendor-app/app/_state/reducers/shopify";
import { getFromLocalStorage, setInLocalStorage } from "@smartrr/shared/utils/localStorageAccess";
export const LoyaltyTiersBanner = () => {
  const { tiersEnabled } = LoyaltyStoreAccess.useInput();
  const [showBanner, setShowBanner] = useState(!tiersEnabled);

  const myShopifyDomain = useMyShopifyDomainSelector();
  const localStorageKey = useMemo(() => `${myShopifyDomain}:showTiersBanner`, [myShopifyDomain]);
  const localStorageEntry = useMemo(() => getFromLocalStorage(localStorageKey), [localStorageKey]);

  // If user dismisses banner it will not show again
  const hideBanner = tiersEnabled || !showBanner || localStorageEntry === "false";

  if (hideBanner) {
    return <React.Fragment />;
  }

  return (
    <Banner
      title="Did you know?"
      status="info"
      icon={CircleInformationMajor}
      onDismiss={() => {
        setShowBanner(false);
        setInLocalStorage(localStorageKey, "false");
      }}
    >
      <Text as="p" variant="bodyMd">
        Tiered programs specifically have shown to have an 80% higher return on investment compared to loyalty
        programs that do not offer tiers.
      </Text>
    </Banner>
  );
};
