import React, { useMemo } from "react";
import { Text, VerticalStack } from "@shopify/polaris";

interface LoyaltyTiersConfirmationModalInnerProps {
  rewardItemHasSubscriberOnly: boolean;
  otpEnabled: boolean;
}

export const LoyaltyTiersConfirmationModalInner = ({
  rewardItemHasSubscriberOnly,
  otpEnabled,
}: LoyaltyTiersConfirmationModalInnerProps): JSX.Element => {
  const confirmationModalText = useMemo(() => {
    if (!otpEnabled) {
      return (
        <VerticalStack gap={"5"}>
          <Text as="span" variant="bodyMd">
            To use loyalty tiers, your{" "}
            <Text as="span" variant="bodyMd" fontWeight="bold">
              &quot;Loyalty availability&quot;
            </Text>{" "}
            setting will change from{" "}
            <Text as="span" variant="bodyMd" fontWeight="bold">
              &quot;Subscribers only&quot;
            </Text>{" "}
            to{" "}
            <Text as="span" variant="bodyMd" fontWeight="bold">
              &quot;All customers&quot;.
            </Text>{" "}
            Also,{" "}
            <Text as="span" variant="bodyMd" fontWeight="bold">
              all subscriber-only rewards will be hidden
            </Text>{" "}
            in the marketplace{" "}
            <Text as="span" variant="bodyMd" fontWeight="bold">
              until their restrictions are updated
            </Text>{" "}
            to the desired tier restriction.
          </Text>
          <Text as="span" variant="bodyMd">
            If your program is live, you{" "}
            <Text as="span" variant="bodyMd" fontWeight="bold">
              won&apos;t be able to reverse
            </Text>{" "}
            this action once you save.
          </Text>
        </VerticalStack>
      );
    }

    if (rewardItemHasSubscriberOnly) {
      return (
        <Text as="span" variant="bodyMd">
          Rewards set to{" "}
          <Text as="span" variant="bodyMd" fontWeight="bold">
            &quot;Subscribers only&quot; will be hidden
          </Text>{" "}
          in the marketplace{" "}
          <Text as="span" variant="bodyMd" fontWeight="bold">
            until their restrictions are updated
          </Text>{" "}
          to the desired tier restriction.
        </Text>
      );
    }

    return <React.Fragment />;
  }, [rewardItemHasSubscriberOnly, otpEnabled]);

  return confirmationModalText;
};
