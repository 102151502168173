import { useCurrentOrganization } from "@vendor-app/utils/useCurrentOrganization";
import React from "react";
import { CurrencyListProps, LoyaltyCurrencyList } from "./LoyaltyCurrencyList";
import { LoyaltyCurrencyHeader } from "./LoyaltyCurrencyHeader";
import { Card, VerticalStack } from "@shopify/polaris";

const LoyaltyCurrencyContent = ({ currencies, shopCurrency }: CurrencyListProps) => {
  return (
    <Card>
      <VerticalStack gap={"1"}>
        <LoyaltyCurrencyHeader />
        <LoyaltyCurrencyList currencies={currencies} shopCurrency={shopCurrency} />
      </VerticalStack>
    </Card>
  );
};

export const LoyaltyCurrency = (): JSX.Element => {
  const organization = useCurrentOrganization();
  const currencyList = organization.shopifyStoreData?.enabled_presentment_currencies;
  const shopCurrency = organization.shopifyStoreData?.currency ?? "USD";

  if (currencyList && currencyList.length > 1) {
    return <LoyaltyCurrencyContent currencies={currencyList} shopCurrency={shopCurrency} />;
  }

  return <React.Fragment></React.Fragment>;
};
