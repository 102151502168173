import { Text, VerticalStack } from "@shopify/polaris";
import { CurrencyCode } from "@smartrr/shared/currencyCode";
import React, { useMemo } from "react";
import styled from "styled-components";
import { LoyaltyCurrencyItem } from "../LoyaltyCurrencyItem";

const CurrencyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 10px;

  > span:nth-of-type(1) {
    width: 58%;
  }

  > span:nth-of-type(2) {
    width: 38%;
  }
`;
export interface CurrencyListProps {
  currencies: CurrencyCode[];
  shopCurrency: CurrencyCode;
}
export const LoyaltyCurrencyList = ({ currencies, shopCurrency }: CurrencyListProps) => {
  const marketCurrencies = useMemo(() => {
    return currencies.filter(c => c !== shopCurrency);
  }, [currencies]);

  return (
    <VerticalStack>
      <CurrencyContainer>
        <Text as="span" color="subdued">
          Currency
        </Text>
        <Text as="span" color="subdued">
          Multiplier
        </Text>
      </CurrencyContainer>
      <VerticalStack gap={"2"}>
        <LoyaltyCurrencyItem code={shopCurrency} key={0} isShopCurrency={true} />
        {marketCurrencies.map((c, index) => (
          <LoyaltyCurrencyItem code={c} key={index + 1} isShopCurrency={false} />
        ))}
      </VerticalStack>
    </VerticalStack>
  );
};
