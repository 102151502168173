import { HorizontalStack, Modal, Text, VerticalStack } from "@shopify/polaris";
import { LoyaltyValidation } from "@smartrr/shared/interfaces/loyalty/validation";
import { createSmartrrModal } from "@vendor-app/utils/createModalStore";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AvailableToSelect } from "../components/AvailableToSelect";
import { DiscountTypeSelect } from "../components/DiscountTypeSelect";
import { generateNewIncentive, getTiersFromIds, setTiersForIncentives } from "../utils";
import { LoyaltySelection } from "../../../LoyaltyOTP";
import { InputSideBySideNoLabelWrapper, InputSideBySideWrapper } from "../constants";
import { ColorSelectInput } from "../components/ColorSelectInput";
import { rgbToHex } from "@mui/material";
import { PointsNeededInput } from "../components/PointsNeededInput";
import { ColoredThumbnail } from "../../components/ColoredThumbnail";
import { DiscountValueInput } from "../components/DiscountValueInput";
import { LoyaltyStoreAccess } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store";
import { validateDataWithZodSchema } from "@smartrr/shared/utils/validateDataWithZodSchema";
import { VisibilityCheckbox } from "../components/VisibilityCheckbox";
import { ErrorMessageWithIcon } from "../components/ErrorMessageWithIcon";
import { validateError } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/libs/utils/validateError";

interface LoyaltyIncentiveModalProps {
  incentive: LoyaltyValidation.Incentive.Type | undefined;
}

export const useLoyaltyIncentiveModalStore = createSmartrrModal<LoyaltyIncentiveModalProps>();

export const LoyaltyIncentiveModal = () => {
  const { isModalOpen, modalPayload: payload } = useLoyaltyIncentiveModalStore();
  const [incentive, setIncentive] = useState<LoyaltyValidation.Incentive.Type | undefined>();
  const tiersEnabled = LoyaltyStoreAccess.useIsTiersEnabled();

  useEffect(() => {
    // Resetting state after close
    if (!isModalOpen) {
      setIncentive(undefined);
    }
    // Modal will have already mounted, once opened we'll set the payload incentive if provided
    if (isModalOpen && payload?.incentive && !incentive) {
      setIncentive(payload.incentive);
    } else if (isModalOpen && !incentive) {
      setIncentive(generateNewIncentive(tiersEnabled));
    }
  }, [isModalOpen]);

  if (!incentive) {
    return <React.Fragment />;
  }

  return <LoyaltyIncentiveModalInner initialIncentive={incentive} />;
};

const LoyaltyIncentiveModalInner = ({
  initialIncentive,
}: {
  initialIncentive: LoyaltyValidation.Incentive.Type;
}) => {
  const { isModalOpen, closeModal, modalPayload: payload } = useLoyaltyIncentiveModalStore();
  const [incentive, setIncentive] = useState<LoyaltyValidation.Incentive.Type>(initialIncentive);

  const actions = LoyaltyStoreAccess.useActions();
  const { symbol } = LoyaltyStoreAccess.useCurrency();
  const { tiers } = LoyaltyStoreAccess.useInput();
  const tiersEnabled = LoyaltyStoreAccess.useIsTiersEnabled();

  const isEditing = useMemo(() => !!payload?.incentive, [payload?.incentive]);

  // Need to validate before being passed to api
  const hasError = useMemo(() => {
    const response = validateDataWithZodSchema(LoyaltyValidation.Incentive.schema, incentive);
    return !response.success;
  }, [incentive]);

  const onRemove = useCallback(() => {
    actions.marketplace.incentive.removeById(initialIncentive.id);
    closeModal();
  }, [initialIncentive]);

  const onConfirm = useCallback(() => {
    if (payload?.incentive) {
      actions.marketplace.incentive.updateById(payload.incentive.id, incentive);
    } else {
      actions.marketplace.incentive.add(incentive);
    }
    closeModal();
  }, [incentive]);

  const formattedModalTitle = useMemo(
    () =>
      (incentive.priceRule.type === "fixed_amount"
        ? `${symbol}${-incentive.priceRule.value}`
        : `${-incentive.priceRule.value}%`) + " off",
    [incentive]
  );

  const formattedIncentiveColor = useMemo(
    () => (incentive.color ? rgbToHex(incentive.color) : ""),
    [incentive.color]
  );

  const availableToValue = useMemo(() => {
    if (tiersEnabled && incentive.tiers?.length) {
      const incentiveTiers = getTiersFromIds(tiers, incentive.tiers);
      const lowestTier = incentiveTiers.reduce((prev, curr) => (prev.tierRank < curr.tierRank ? prev : curr));
      return String(lowestTier.tierRank);
    }

    if (tiersEnabled && tiers.length) {
      return String(tiers[0].tierRank);
    }

    return incentive.subscriberOnly ? LoyaltySelection.subscribers : LoyaltySelection.all;
  }, [tiers, incentive]);

  return (
    <Modal
      open={isModalOpen}
      onClose={closeModal}
      limitHeight
      title={
        <HorizontalStack align="center" gap={"2"}>
          <ColoredThumbnail color={incentive.color} size="ExtraSmall" />
          <Text variant="headingLg" as="p" fontWeight="regular">
            {formattedModalTitle}
          </Text>
        </HorizontalStack>
      }
      secondaryActions={[
        {
          content: isEditing ? "Delete reward" : "Back",
          onAction: isEditing ? onRemove : closeModal,
          destructive: isEditing,
        },
      ]}
      primaryAction={{
        content: "Confirm",
        onAction: onConfirm,
        disabled: hasError,
      }}
    >
      <Modal.Section>
        <VerticalStack gap={"4"}>
          <InputSideBySideNoLabelWrapper>
            <DiscountTypeSelect
              value={incentive.priceRule.type}
              onChange={v => {
                setIncentive(prev => ({
                  ...prev,
                  priceRule: {
                    ...prev.priceRule,
                    type: v,
                  },
                }));
              }}
            />
            <DiscountValueInput
              value={incentive.priceRule.value}
              type={incentive.priceRule.type}
              onChange={v => {
                setIncentive(prev => ({
                  ...prev,
                  priceRule: {
                    ...prev.priceRule,
                    value: String(Number(v) * -1),
                  },
                }));
              }}
            />
          </InputSideBySideNoLabelWrapper>
          {validateError(
            LoyaltyValidation.Incentive.schema.shape.priceRule.shape.value,
            incentive.priceRule.value,
            true
          ) ? (
            <ErrorMessageWithIcon message="Please add a value greater than zero" />
          ) : (
            <React.Fragment />
          )}
          <InputSideBySideWrapper>
            <ColorSelectInput
              color={formattedIncentiveColor}
              onChange={v => {
                setIncentive(prev => ({
                  ...prev,
                  color: v,
                }));
              }}
            />
            <PointsNeededInput
              value={incentive.costInPoints}
              onChange={v => {
                setIncentive(prev => ({
                  ...prev,
                  costInPoints: Number(v),
                }));
              }}
            />
          </InputSideBySideWrapper>
          <AvailableToSelect
            onChange={v =>
              setIncentive(prev => ({
                ...prev,
                subscriberOnly: v === LoyaltySelection.subscribers,
                tiers: setTiersForIncentives(Number(v), tiers, incentive, actions.tiers.update),
              }))
            }
            value={availableToValue}
          />
          <VisibilityCheckbox
            onChange={() => {
              setIncentive(prev => ({
                ...prev,
                hidden: !prev.hidden,
              }));
            }}
            value={!incentive.hidden}
          />
        </VerticalStack>
      </Modal.Section>
    </Modal>
  );
};
