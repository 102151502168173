import { ContextualSaveBar } from "@shopify/polaris";
import { noawait } from "@smartrr/shared/utils/noawait";
import React, { useCallback, useMemo } from "react";

import { LoyaltyStoreAccess } from "../../../store";
import { useLoyaltyConfirmationModalStore } from "../LoyaltyConfirmationModal";
import { useTiersConfirmationModalStore } from "../LoyaltyTiersConfirmationModal";
import { useTiersValidationModalStore } from "../LoyaltyTiersValidationModal";
import { getDuplicatesFromArray } from "../../../libs/utils/getDuplicatesFromArray";
import { checkForEmptyValues } from "../../../libs/utils/checkForEmptyValues";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";

export const LoyaltySave = () => {
  const actions = LoyaltyStoreAccess.useActions();
  const input = LoyaltyStoreAccess.useInput();
  const savedValues = LoyaltyStoreAccess.useSavedValues();
  const isLoading = LoyaltyStoreAccess.useLoading();
  const hasFormChanged = LoyaltyStoreAccess.useHasFormChanged();
  const { showError, errorMessage } = LoyaltyStoreAccess.useErrors();
  const { openModal: openLoyaltyConfirmationModal } = useLoyaltyConfirmationModalStore();
  const { openModal: openTiersConfirmationModal } = useTiersConfirmationModalStore();
  const { openModal: openTiersValidationModal } = useTiersValidationModalStore();
  const { addToast } = useToast();

  const tiersEnabledHasChanges = useMemo(
    () => savedValues.tiersEnabled !== input.tiersEnabled,
    [savedValues.tiersEnabled, input.tiersEnabled]
  );
  const duplicatesWithinTiers = useMemo(
    () => getDuplicatesFromArray(input.tiers, ["products", "incentives"]),
    [input.tiers]
  );
  const doTiersHaveEqualValues = useMemo(() => !!duplicatesWithinTiers.length, [input.tiers]);
  const doTiersHaveEmptyValues = useMemo(
    () => checkForEmptyValues(input.tiers, ["products", "incentives"], [0]),
    [input.tiers]
  );

  const doRewardsItemsHaveSubscriberOnly = useMemo(() => {
    const variantsHaveSubscriberOnly = input.variants.some(variant => variant.subscriberOnly);
    const IncentivesHaveSubscriberOnly = input.incentives.some(incentive => incentive.subscriberOnly);

    return variantsHaveSubscriberOnly || IncentivesHaveSubscriberOnly;
  }, [input.incentives, input.variants]);

  const isSubscriberOnlyEnabled = useMemo(
    () => !input.otpEnabled || doRewardsItemsHaveSubscriberOnly,
    [input.otpEnabled, doRewardsItemsHaveSubscriberOnly]
  );
  const showTiersConfirmationModal = tiersEnabledHasChanges && isSubscriberOnlyEnabled;

  const onSaveHandler = useCallback(() => {
    const pointsInRewardHasChanges =
      savedValues.referralProgram?.pointsInReward !== input.referralProgram?.pointsInReward &&
      input.referralProgram?.pointsInReward === 0;

    const priceRuleValueHasChanges =
      savedValues.referralProgram?.priceRule.value !== input.referralProgram?.priceRule.value &&
      input.referralProgram?.priceRule.value === "0";

    if (doTiersHaveEqualValues || doTiersHaveEmptyValues) {
      openTiersValidationModal({});
      return;
    }

    if (showTiersConfirmationModal) {
      openTiersConfirmationModal({
        values: {
          rewardsItemHasSubscriberOnly: doRewardsItemsHaveSubscriberOnly,
          otpEnabled: input.otpEnabled,
        },
      });
      return;
    }

    if (pointsInRewardHasChanges || priceRuleValueHasChanges) {
      openLoyaltyConfirmationModal({
        actions: {
          save: actions.save,
        },
        values: {
          pointsInRewards: input.referralProgram?.pointsInReward ?? 0,
          priceRuleValue: input.referralProgram?.priceRule.value ?? "0",
        },
      });
      return;
    }

    noawait(() => actions.save());

    if (showError && errorMessage) {
      addToast(errorMessage);
    }
  }, [input.referralProgram, input.tiersEnabled, input.tiers]);

  return (
    <React.Fragment>
      {hasFormChanged ? (
        <ContextualSaveBar
          message="Unsaved changes"
          discardAction={{
            content: "Discard",
            onAction: () => actions.discardChanges(),
          }}
          saveAction={{
            loading: isLoading,
            content: "Save",
            onAction: onSaveHandler,
          }}
        />
      ) : null}
    </React.Fragment>
  );
};
