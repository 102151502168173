import { Text, TextField, VerticalStack } from "@shopify/polaris";
import React from "react";

import { LoyaltyStoreAccess } from "../../../store";
import { LoyaltyForm } from "../../../store/form";
import { displayError } from "../../../libs/utils/displayError";

export const LoyaltyDescription = () => {
  const description = LoyaltyStoreAccess.useValue("description");
  const actions = LoyaltyStoreAccess.useActions();
  const showError = LoyaltyStoreAccess.useShowError();

  return (
    <VerticalStack gap={"2"}>
      <Text as="h3" variant="headingSm" fontWeight="semibold">
        Loyalty program description
      </Text>
      <TextField
        label="description"
        labelHidden
        value={description}
        placeholder={
          'Optional—will show when a customer clicks on "How do I earn points?" alongside event-point breakdown.'
        }
        maxLength={150}
        onChange={s => actions.updateDescription(s)}
        multiline={2}
        showCharacterCount
        autoComplete="off"
        error={displayError(LoyaltyForm.Program.schema.shape.description, description, showError)}
      />
    </VerticalStack>
  );
};
