import { VerticalStack } from "@shopify/polaris";
import { LoyaltyTiersStatus } from "./LoyaltyTiersStatus";
import { LoyaltyTiersList } from "./LoyaltyTiersList";
import { LoyaltyTiersBanner } from "./LoyaltyTiersBanner";
import { LoyaltyTiersRemoveModal } from "./LoyaltyTiersList/LoyaltyTiersRemoveModal";
import React from "react";

export const LoyaltyTiers = (): JSX.Element => {
  return (
    <React.Fragment>
      <VerticalStack gap="6">
        <LoyaltyTiersBanner />
        <LoyaltyTiersStatus />
        <LoyaltyTiersList />
      </VerticalStack>
      <LoyaltyTiersRemoveModal />
    </React.Fragment>
  );
};
