import { LoyaltyApi } from "@smartrr/shared/interfaces/loyalty/api";

export const getTierOptions = (tiers: LoyaltyApi.Tier.Type[]): { label: string; value: string }[] => {
  return tiers.map((tier, index) => {
    let label = `${tier.tierName} tier and up`;
    const isLastTier = index === tiers.length - 1;

    if (index === 0) {
      label = `All customers`;
    }

    if (isLastTier) {
      label = `${tier.tierName} tier`;
    }

    return {
      label,
      value: String(tier.tierRank),
    };
  });
};
