import { useMemo } from "react";
import { LoyaltyRewardsTableAccess } from "../store";
import { BulkAction } from "@shopify/polaris/build/ts/src/components/BulkActions";
import { useLoyaltyProductModalStore } from "../../modals/LoyaltyProductModal";
import { useLoyaltyIncentiveModalStore } from "../../modals/LoyaltyIncentiveModal";
import { useBulkDeleteRewardModalStore } from "../../modals/BulkDeleteRewardModal";

const useEditRewardItemAction = (): BulkAction | undefined => {
  const selectedResources = LoyaltyRewardsTableAccess.useSelectedData();
  const openLoyaltyProductModal = useLoyaltyProductModalStore(state => state.openModal);
  const openLoyaltyIncentiveModal = useLoyaltyIncentiveModalStore(state => state.openModal);

  return useMemo(() => {
    // Edit will only be available if 1 item is selected at a time
    if (selectedResources.length !== 1) {
      return;
    }

    return {
      content: "Edit",
      onAction: () =>
        selectedResources[0].rewardType === "INCENTIVE"
          ? openLoyaltyIncentiveModal({ incentive: selectedResources[0] })
          : openLoyaltyProductModal({ product: selectedResources[0] }),
    };
  }, [selectedResources]);
};

const useDeleteRewardItem = (): BulkAction => {
  const selectedResources = LoyaltyRewardsTableAccess.useSelectedData();
  const { openModal } = useBulkDeleteRewardModalStore();
  const selectedIds = useMemo(() => selectedResources.map(x => x.id), [selectedResources]);
  return useMemo(
    () => ({
      content: `Delete`,
      onAction: () => openModal({ ids: selectedIds }),
      destructive: true,
    }),
    [selectedIds]
  );
};

export const useLoyaltyRewardsBulkActions = () => {
  const selectedResources = LoyaltyRewardsTableAccess.useSelectedData();
  const editRewardAction = useEditRewardItemAction();
  const bulkDeleteRewardsAction = useDeleteRewardItem();
  const promotedBulkActions: BulkAction[] = [];

  if (editRewardAction) {
    promotedBulkActions.push(editRewardAction);
  }

  return useMemo(
    () => ({ promotedBulkActions, bulkActions: [bulkDeleteRewardsAction] }),
    [selectedResources, editRewardAction]
  );
};
