import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import { createLazyFileRoute } from "@tanstack/react-router";

import { ErrorBoundary } from "@vendor-app/app/_sharedComponents/ErrorBoundary";
import { AdminLoyaltyRoute } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute";
import { AuthorizedRoute } from "@vendor-app/app/AdminRoute/components/authorization/AuthorizedRoute";

export const Route = createLazyFileRoute("/admin/loyalty")({
  component: Index,
  errorComponent: ({ error, info }) => <ErrorBoundary error={error} info={info} />,
});

function Index() {
  return AuthorizedRoute(FeatureEnum.LOYALTY, <AdminLoyaltyRoute />);
}
